import axios from 'axios'
import { endLoading, startLoading } from '../login/reducer'
import { toast } from 'react-toastify'
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const SecretKeyVerify = async (body: any, userType: any, navigate: (p: string) => void, organization: string, dispatch: any, setSecretKey: React.Dispatch<React.SetStateAction<string>>, sos: any) => {
    dispatch(startLoading());
    const { header1 } = authorize();
    try {
        const response = await axios.post(`${baseURL}/user/verify`, body,{headers: header1});
        if (response.data.message.code === successCode) {
            sessionStorage.setItem('authStaff', 'Verified');
            dispatch(endLoading());
            if (userType === "Admin") {
                if(sos) {
                    navigate('/staff-configuration');
                } else {
                    navigate('/q15-staff-configuration');
                }
            } else if (userType === "Super Admin") {
            navigate('/organization-details');
            } else if (userType === "System Admin") {
            navigate(`/organization-update/${organization}`);
            } else if (userType === "Staff") {
                if(sos) {
                    navigate('/sos-staff');
                } else {
                    navigate(`/q15-report`);
                }
            
            } else if (userType === "Receptionist") {
                    navigate(`/staff-configuration`);
            } else {
            navigate('/staff-table');
            }
        } else {
            toast.error(response.data.message.description);
            dispatch(endLoading());
            setSecretKey("");
        }
        } catch (error) {
        dispatch(endLoading());
        console.error("Error during login:", error);
        toast.error("An error occurred during login.");
        }
    };
    

export const handleUnblock = async(keySecret: any, keyUserName: any, navigate: (p: string) => void, userType: any, organization: string, dispatch: any, setSecretKey: React.Dispatch<React.SetStateAction<string>>) => {
    dispatch(startLoading());
        const { header1 } = authorize();
        try {
            const response = await axios.post(`${baseURL}/user/unblock?secretKey=${keySecret}&username=${keyUserName}`,{headers: header1});
            if(response.data.message.code === successCode) {
                dispatch(endLoading());
                navigate('/login');
            }else {
                toast.error(response.data.message.description);
                dispatch(endLoading());
                setSecretKey("");
            }
        }  catch (error) {
            dispatch(endLoading());
            console.error("Error during login:", error);
            toast.error("An error occurred during unblock user.");
        }
    }