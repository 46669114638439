import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./form.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { baseURL, successCode } from "../../configuration/url";
import { authorize, formatPhoneNumber } from "../../helpers/common";
import { getAllOrganizationDetails } from "../../slices/thunk";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import * as Constants from "../Constants/Constant";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import uploadImage from "../../assets/images/rectangle-59991.svg";
import moment, {  } from "moment";
import { useTimezoneSelect, allTimezones } from "react-timezone-select"
import Loader from "../../components/loader/Loader";

const labelStyle = "original"
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
}
interface DropdownItem {
  id: string;
  value: string;
  type: string;
}
interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}
interface FormData {
  organizationName: string;
  OrganizationType: string;
  organizationId: string;
  duration: string,
  startTime: any,
  mobileNumber: string;
  email: string;
  websiteUrl: string;
  hippaPrivacyOfficerFirstName: string;
  hippaPrivacyOfficerLastName: string;
  proximityVerification: string;
  geofencing: string;
  q15Access: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  Country: string;
  zip: string;
  cFPerson: string;
  cLPerson: string;
  cEmail: string;
  cPhone: string;
  npi: string;
  tin: string;
  timeZone: string;
  logo: string;
  sos: boolean;
}
interface OrganizationFormProps {
  modal: boolean;
  toggle: () => void;
  currentPage: number;
}
const OrganizationForm: React.FC<OrganizationFormProps> = ({ modal, toggle, currentPage }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })
  const dispatch = useDispatch<any>()
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [cityDropDown, setCityDropDown] = useState(new Array<any>());
  const [dropdownData, setDropdownData] = useState<Dropdown[]>([]);
  const initselect = {
    Country: [],
    state: [],
    OrganizationType: null,
  }
  const [selectedValues, setSelectedValues] = useState<any>(initselect);
  const initFormData = {
    organizationName: "",
    OrganizationType: "",
    organizationId: '',
    duration: "",
    startTime: null,
    mobileNumber: "",
    email: "",
    websiteUrl: "",
    hippaPrivacyOfficerFirstName: "",
    hippaPrivacyOfficerLastName: "",
    proximityVerification: "",
    geofencing: "",
    q15Access: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    Country: "",
    zip: "",
    cFPerson: "",
    cLPerson: "",
    cEmail: "",
    cPhone: "",
    npi: "",
    tin: "",
    timeZone: "",
    logo: "",
    sos: false
  }
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({});
  const [ loading , setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=OrganizationType`, { headers: header1 })
        if (response?.data.message.code == successCode) {
          setDropdownData(response?.data.data[0].list);
        }
        else {
          console.error('Error fetching dropdown data:', response?.data.message.description);
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  const hiddenFileInput = useRef<any>(null);

  const handleProfileChange = () => {
    if (hiddenFileInput !== null) {
      hiddenFileInput.current.click();
    }
  }

  const handleImageChange = (e: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      formData.logo = reader.result !== null ? reader.result.toString() : ""
      setFormData({ ...formData });

    }
    reader.readAsDataURL(e.target.files[0]);
  }


  const handleInputChange = (field: keyof FormData, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const [loading1, setLoading1] = useState(false);
  const handlePostalCodeChange = async (e: any) => {
    formData.zip = e.target.value;
    setFormData({ ...formData });
    setNameError({ ...nameError, zip: false })
    if (e.target.value.length === 5) {
      setLoading1(true)
      try {
        const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
        formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
        formData.Country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
        formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
        setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]].map((k: any) => k.city_en) : [])
        setLoading1(false)
      } catch (error) {
        console.error('Error fetching city:', error);
        setLoading1(false)
      }
    } else {
      formData.state = "";
      formData.Country = "";
      formData.city = "";
    }
    setFormData({ ...formData });
  };

  const handleinputchange = (event: any) => {
    if (event.target.id === 'OrganizationName') {
      formData.organizationName = event.target.value;
    } else if (event.target.id === 'OrganizationEmail') {
      formData.email = event.target.value;
    } else if (event.target.id === 'npi') {
      formData.npi = event.target.value;
    } else if (event.target.id === 'tin') {
      formData.tin = event.target.value;
    } else if (event.target.id === 'OrganizationType') {
      formData.OrganizationType = event.target.value;
    } else if (event.target.id === 'AddressLine1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'AddressLine2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'country') {
      formData.Country = event.target.value;
    } else if (event.target.id === 'MobileNumber') {
      formData.mobileNumber = event.target.value;
    } else if (event.target.id === 'WebsiteURL') {
      formData.websiteUrl = event.target.value;
    } else if (event.target.id === 'ContactPersonFirstName') {
      formData.cFPerson = event.target.value;
    } else if (event.target.id === 'ContactPersonLastName') {
      formData.cLPerson = event.target.value;
    } else if (event.target.id === 'ContactMobile') {
      formData.cPhone = event.target.value;
    } else if (event.target.id === 'ContactEmail') {
      formData.cEmail = event.target.value;
    } else if (event.target.id === 'HIPPAOfficerFirstName') {
      formData.hippaPrivacyOfficerFirstName = event.target.value;
    } else if (event.target.id === 'HIPPAOfficerLastName') {
      formData.hippaPrivacyOfficerLastName = event.target.value;
    }
    setFormData({ ...formData });
  }
  const handleSelectChange = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleCancel = () => {
    setFormData(initFormData);
    setSelectedValues(initselect)
    toggle();
    setNameError({})
  }

  const handleSubmit = async (save: boolean) => {
    setLoading(true)
    try {
      let newErrors = {
        organizationName: !formData.organizationName,
        email: !formData.email,
        startTime: !formData.startTime,
        timeZone: !formData.timeZone,
        mobileNumber: !formData.mobileNumber,
        duration: !formData.duration,
        OrganizationType: !selectedValues.OrganizationType,
        organizationId: !formData.organizationId,
        websiteUrl: !formData.websiteUrl,
        hippaPrivacyOfficerFirstName: !formData.hippaPrivacyOfficerFirstName,
        hippaPrivacyOfficerLastName: !formData.hippaPrivacyOfficerLastName,
        proximityVerification: !formData.proximityVerification,
        geofencing: !formData.geofencing,
        q15Access: !formData.q15Access,
        addressLine1: !formData.addressLine1,
        addressLine2: !formData.addressLine2,
        city: !formData.city,
        state: !formData.state,
        Country: !formData.Country,
        zip: !formData.zip,
        cFPerson: !formData.cFPerson,
        cLPerson: !formData.cLPerson,
        cEmail: !formData.cEmail,
        cPhone: !formData.cPhone,
        npi: !formData.npi,
        tin: !formData.tin,
        logo: !formData.logo,
        sos: !formData.sos,

      }
      setNameError(newErrors)


      if (!formData.organizationName || !formData.duration || !formData.startTime ||
        !formData.email || !formData.duration || !formData.startTime ||
        !formData.mobileNumber || !formData.timeZone) {
        toast.error('Please fill the required fields');
        const hasErrors = Object.values(newErrors).some(error => error);
        if (hasErrors) {
          setLoading(false)
          return;
        }
        setLoading(false)
        return;
      }
      else if (!emailRegex.test(formData.email)) {
        toast.error("Invalid Email Address");
        setLoading(false)
        return;
      } else if (formData.zip.length > 0 && formData.city === "" && formData.state === "") {
        formData.city = "";
        formData.state = "";
        formData.Country = "";
        toast.error("Please Enter Valid Zip Code");
        setLoading(false)
        return;
      }
      const requestBody = {
        organizationdetails: [
          {
            id: formData.organizationId,
            name: formData.organizationName,
            type: selectedValues.OrganizationType,
            tin: formData.tin,
            npi: formData.npi,
          },
        ],
        shift: {
          duration: formData.duration.replace(/^(\d)$/, '0$1'),
          startTime: moment(formData.startTime).format("HH:mm")
        },
        contact: [
          {
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            city: formData.city,
            state: formData.state || '',
            country: formData.Country || '',
            zip: formData.zip
          }
        ],
        email: formData.email,
        mobileNumber: formData.mobileNumber,
        websiteUrl: formData.websiteUrl,
        proximityVerification: formData.proximityVerification,
        geofencing: formData.geofencing,
        q15Access: formData.q15Access,
        timeZone: formData.timeZone,
        logo: formData.logo,
        sos: formData.sos,
        pointofcontact: [
          {
            firstName: formData.cFPerson,
            lastName: formData.cLPerson,
            email: formData.cEmail,
            phoneNumber: formData.cPhone,
          }
        ],
        hippaprivacyofficer: [
          {
            firstName: formData.hippaPrivacyOfficerFirstName,
            lastName: formData.hippaPrivacyOfficerLastName,
          },
        ],
      }
      const { header1 } = authorize();
      const response = await axios.post(
        `${baseURL}/org/register`, requestBody, { headers: header1 }
      );

      if (response.data.message.code === successCode) {
        toast.success(response.data.message.description);
        if (!save) {
          handleCancel()
          dispatch(getAllOrganizationDetails(dispatch, currentPage, ''));
          setLoading(false)
        }
        setLoading(false)
        dispatch(getAllOrganizationDetails(dispatch, currentPage, ''));
      } else {
        console.error("Error registering:", response.data.message);
        toast.warning(`Error: ${response.data.message.description}`);
      }
      setLoading(false)
    } catch (error) {
      console.error("Error registering:", error);
      setLoading(false)
    }
  };

  return (
    <>
    {loading && <Loader/>}
    <Modal isOpen={modal}
      // toggle={toggle} 
      style={{ overflowY: 'auto' }} centered size="lg">
      <div className="d-flex align-items-center justify-content-center vh-90">
        <div className="row">
          <div className="container col-md-12">
            <ModalHeader toggle={handleCancel}>Client Register<span>
              <div onClick={handleProfileChange} hidden={formData.logo !== null && formData.logo !== "" && formData.logo !== "string"} style={{ left: "unset", width: "140px" }} className="vector-group">
                <img className="frame-child4" style={{ width: '120px', cursor: 'pointer' }} alt="" src={uploadImage} />
                <div className="upload-logo-pic1" style={{ fontSize: '13px', color: 'blue', position: 'absolute', top: '16px', left: '13px', zIndex: 1, cursor: 'pointer' }}>
                  Upload Logo
                </div>
              </div>
              <div hidden={formData.logo === null || formData.logo === "" || formData.logo === "string"} style={{ left: "unset", width: "182px" }} className="vector-group">
                <div style={{ position: 'absolute', top: "2px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} src={formData.logo} onClick={handleProfileChange} /><input className="vector-group" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
              </div>
            </span></ModalHeader>
            <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
              <form onSubmit={() => handleSubmit(false)}>
                <div className="row w-100 ">
                  <div className='col-md-6 mb-2'>
                    <TextField
                      id="OrganizationName"
                      label="Organization Name"
                      variant="outlined"
                      fullWidth
                      value={formData.organizationName}
                      onChange={(e) => {
                        setFormData({ ...formData, organizationName: e.target.value })
                        setNameError({ ...nameError, organizationName: false })
                      }}
                      error={!!nameError.organizationName}
                      helperText={nameError.organizationName ? 'Organization Name is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-6 mb-2'>
                    <TextField
                      id="OrganizationEmail"
                      label="Organization Email"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value })
                        setNameError({ ...nameError, email: false })
                      }}
                      error={!!nameError.email}
                      helperText={nameError.email ? 'Organization Email is required' : ''}
                      required
                    />
                  </div>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-6 mb-2'>
                    <TextField
                      id="npi" label="NPI#"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, npi: e.target.value })
                        setNameError({ ...nameError, npi: false })
                      }}
                      error={!!nameError.npi}
                      helperText={nameError.npi ? 'npi is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-6 mb-2'>
                    <TextField
                      id="tin"
                      label="TIN"
                      variant="outlined"
                      fullWidth onChange={(e) => {
                        setFormData({ ...formData, tin: e.target.value })
                        setNameError({ ...nameError, tin: false })
                      }}
                      error={!!nameError.tin}
                      helperText={nameError.tin ? 'tin is required' : ''}
                      required
                    />
                  </div>
                </div>
                <div className="row w-100  mb-2">
                  <div className="col-md-6">
                    <Autocomplete id="OrganizationType"
                      options={dropdownData?.map((item: any) => item.value)}
                      value={selectedValues.OrganizationType}
                      onChange={(event: any, value: any) => {
                        setSelectedValues({ ...selectedValues, OrganizationType: value });
                        setNameError({ ...nameError, OrganizationType: false })
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params}
                          variant="outlined"
                          label="Organization Type"
                          margin="none"
                          fullWidth
                          error={!!nameError.OrganizationType}
                          helperText={nameError.OrganizationType ? 'Organization type is required' : ''}
                          required
                        />
                      )}
                    />
                  </div>
                  <div className='col-md-6'>
                    <TextField
                      id="WebsiteURL"
                      label="Website URL"
                      variant="outlined" fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, websiteUrl: e.target.value })
                        setNameError({ ...nameError, websiteUrl: false })
                      }}
                      error={!!nameError.websiteUrl}
                      helperText={nameError.websiteUrl ? 'websiteUrl is required' : ''}
                      required
                    />
                  </div>
                </div>

                <div className="row w-100 ">
                  <div className='col-md-4'>
                    <TextField
                      id="HIPPAOfficerFirstName"
                      label="HIPPA Officer First Name"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, hippaPrivacyOfficerFirstName: e.target.value })
                        setNameError({ ...nameError, hippaPrivacyOfficerFirstName: false })
                      }}
                      error={!!nameError.hippaPrivacyOfficerFirstName}
                      helperText={nameError.hippaPrivacyOfficerFirstName ? 'Hippa officer First Name is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4'>
                    <TextField
                      id="HIPPAOfficerLastName"
                      label="HIPPA Officer Last Name"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, hippaPrivacyOfficerLastName: e.target.value })
                        setNameError({ ...nameError, hippaPrivacyOfficerLastName: false })
                      }}
                      error={!!nameError.hippaPrivacyOfficerLastName}
                      helperText={nameError.hippaPrivacyOfficerLastName ? 'Hippa officer Last Name is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4'>
                    <TextField
                      id="MobileNumber"
                      label="Mobile Number"
                      value={formatPhoneNumber(formData.mobileNumber)}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, mobileNumber: e.target.value })
                        setNameError({ ...nameError, mobileNumber: false })
                      }}
                      error={!!nameError.mobileNumber}
                      helperText={nameError.mobileNumber ? 'Mobile Number is required' : ''}
                      required
                    />
                  </div>
                </div>
                <div className="row w-100  mt-2">
                  <div className='col-md-4'>
                    <TextField
                      id="outlined-basic-1"
                      label="Shift Duration"
                      variant="outlined"
                      fullWidth onChange={(e) => {
                        setFormData({ ...formData, duration: e.target.value })
                        setNameError({ ...nameError, duration: false })
                      }}
                      error={!!nameError.duration}
                      helperText={nameError.duration ? 'Duration is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4' >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div style={{ width: '100%' }}>
                        <TimePicker format="HH:mm" label={<span style={{color:!!nameError.duration ? "#d32f2f" : " "}}>Start Time</span>}
                          sx={{ border: !!nameError.duration ? "0.30px solid #d32f2f" : " ", borderRadius: "4px"}}
                          value={formData.startTime}
                          defaultValue
                          onChange={(newValue: any) => {
                            formData.startTime = newValue;
                            setFormData({ ...formData });
                            if (newValue) {
                              setNameError({ ...nameError, duration: false });
                            }
                          }}

                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                  <div className='col-md-4'>
                    <FormControl fullWidth>
                      <InputLabel error={!!nameError.timeZone} id="timeZone-label">Time Zone</InputLabel>
                      <Select
                        labelId="timeZone-label"
                        id="timeZone"
                        value={formData.timeZone}
                        onChange={(e) => {
                          setNameError({ ...nameError, timeZone: false });
                          handleSelectChange("timeZone", e.target.value)
                        }}
                        label="Time Zone"
                        MenuProps={MenuProps}
                        error={!!nameError.timeZone}
                      // helperText={nameError.timeZone ? 'Time Zone is required' : ''}
                      >
                        {options?.length > 0 ? options.map((option) => (
                          <MenuItem style={{ fontSize: '11px' }} key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )) : 
                          <MenuItem>
                            No Time Zone Data
                          </MenuItem>
                        }
                      </Select>
                    </FormControl>
                  </div> 
                </div>
                <div className="mt-3">
                  <label htmlFor="OrganizationType" className="label d-flex ">
                    Contact Person
                  </label>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-6'>
                    <TextField
                      id="ContactPersonFirstName"
                      label="Contact Person First Name"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, cFPerson: e.target.value })
                        setNameError({ ...nameError, cFPerson: false })
                      }}
                      error={!!nameError.cFPerson}
                      helperText={nameError.cFPerson ? 'Contact Person First Name is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <TextField
                      id="ContactPersonLastName"
                      label="Contact Person Last Name"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, cLPerson: e.target.value })
                        setNameError({ ...nameError, cLPerson: false })
                      }}
                      error={!!nameError.cLPerson}
                      helperText={nameError.cLPerson ? 'Contact Person Last Name is required' : ''}
                      required
                    />
                  </div>
                </div>
                <div className="row w-100 mt-2">
                  <div className='col-md-6'>
                    <TextField
                      id="ContactEmail"
                      label="Contact Email"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, cEmail: e.target.value })
                        setNameError({ ...nameError, cEmail: false })
                      }}
                      error={!!nameError.cEmail}
                      helperText={nameError.cEmail ? 'Contact Email is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <TextField
                      id="ContactMobile"
                      label="Contact Mobile"
                      value={formatPhoneNumber(formData.cPhone)}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, cPhone: e.target.value })
                        setNameError({ ...nameError, cPhone: false })
                      }}
                      error={!!nameError.cPhone}
                      helperText={nameError.cPhone ? 'Phone Number is required' : ''}
                      required
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label htmlFor="Address" className="label d-flex ">
                    Address
                  </label>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-4 mb-2'>
                    <TextField
                      id="addessLine1"
                      label="addess Line 1"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, addressLine1: e.target.value })
                      }}
                      required
                    />
                  </div>
                  <div className='col-md-4'>
                    <TextField
                      id="addressLine2"
                      label="address Line 2"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setFormData({ ...formData, addressLine2: e.target.value })
                      }}
                      required
                    />
                  </div>
                  <div className='col-md-4'>
                    <TextField
                      id="zipcode"
                      label="Zip/Postal Code"
                      variant="outlined"
                      fullWidth
                      value={formData.zip}
                      onChange={handlePostalCodeChange}
                      error={!!nameError.zip}
                      helperText={nameError.zip ? 'Zip is required' : ''}
                      required
                      inputProps={{
                        maxLength: 5,
                        pattern: "\\d{5}",
                      }}
                    />
                    {loading1 && <CircularProgress size={'25px'} />}
                  </div>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-4'>
                    {cityDropDown !== null && cityDropDown.length === 1 ?
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={formData.city}
                        onChange={handleinputchange}
                      /> :
                      <Autocomplete
                        id="city"
                        options={cityDropDown}
                        value={formData.city}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                        sx={{ width: "100%" }}
                        size="medium"
                        renderInput={params =>
                          <TextField
                            name=""
                            {...params}
                            variant="outlined"
                            label="City"
                            placeholder=""
                            margin="none"
                            size="medium"
                            fullWidth
                          />
                        }
                      />}
                  </div>
                  <div className='col-md-4'>
                    <TextField
                      id="state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={formData.state}
                      onChange={handleinputchange}
                    />
                  </div>
                  <div className='col-md-4'>
                    <TextField
                      id="country"
                      label="Country"
                      variant="outlined"
                      fullWidth
                      value={formData.Country}
                      onChange={handleinputchange}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="mt-3">
                    <label htmlFor="OrganizationType" className="label d-flex ">
                      Access Control
                    </label>
                  </div>
                  <div className="col-md-3 mt-2">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="q15-access-label">Q15</InputLabel>
                      <Select
                        labelId="q15-access-label"
                        id="q15Access"
                        label="Q15"
                        value={formData.q15Access}
                        onChange={(e) => handleSelectChange('q15Access', e.target.value)}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3 mt-2">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="proximity-label">Proximity</InputLabel>
                      <Select
                        labelId="proximity-label"
                        id="proximity"
                        label="Proximity"
                        value={formData.proximityVerification}
                        onChange={(e) => handleInputChange('proximityVerification', e.target.value)}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3 mt-2">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="geofencing-label">Geo Fencing</InputLabel>
                      <Select
                        labelId="geofencing-label"
                        id="geofencing"
                        label="Geo Fencing"
                        value={formData.geofencing}
                        onChange={(e) => handleSelectChange('geofencing', e.target.value)}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3 mt-2">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="sos-label">SOS</InputLabel>
                      <Select
                          labelId="sos-label"
                          id="sos"
                          label="SOS"
                          onChange={(event) => {
                              // Update formData.sos based on the selected value
                              const value = event.target.value === 'true';
                              setFormData((prevData) => ({ ...prevData, sos: value }));
                          }}
                          value={formData.sos?.toString()}
                          name="sos"
                      >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                      </Select>
                  </FormControl>
                    </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="">
              <div className="d-flex gap-3 justify-content-center">
                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleCancel}></Button>
                <Button label="Save" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSubmit(true)}></Button>
                <Button label="Save & Exit" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSubmit(false)}></Button>
              </div>
            </ModalFooter>

          </div>
        </div>
      </div>
    </Modal>
    </>
  );
};

export default OrganizationForm;