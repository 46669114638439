import { Autocomplete, TextField } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react'
import {
  Modal, ModalFooter, ModalBody, ModalHeader
} from "reactstrap";
import { HttpLogin } from '../../utils/Http';
import { baseURL, successCode } from '../../configuration/url';
import { useDispatch, useSelector } from 'react-redux';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';
import axios from 'axios';

interface AddOneOneProps {
  IsOpen: any
  Close: any
  errors: any
  setErrors: any
  setPatientDate: any
  selectedDate: any
  selectedTab: any
  shifts: any
  forDate:any
  patientDropdown: any
}

const AddOneToOne: React.FC<AddOneOneProps> = ({IsOpen, Close, errors, setErrors, selectedDate, setPatientDate, selectedTab, shifts, forDate, patientDropdown}) => {
  const { organization } = useSelector((state: any) => state.Login)
  const { staffDropdown } = useSelector((state: any) => state.Staff);
  const initFormData = {
      date: "",
      endTime: "",
      id: "",
      oneToOneObserver: "",
      oneToOneObserverName: "",
      organization: "",
      patientId: "",
      patientName: "",
      patientIncharge: "",
      patientInchargeName: "",
      prescribedby: "",
      prescribedbyName: "",
      startTime: ""
  }
  const [Addpatient, setAddpatient] = useState(initFormData);

  const dispatch = useDispatch<any>()
  

  const handleInputChangeadd = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, value } = event.target;
      setAddpatient({ ...Addpatient, [id]: value });
      setErrors({ ...errors, [id]: !value });
  };

  const handleAutocompleteChange = (id: string, value: any, name: string) => {
      setAddpatient({
          ...Addpatient,
          [id]: value ? value.id : null,
          [name]: value ? `${value.name}` : ''
      });
      setErrors({ ...errors, [id]: !value });
  };

  const handleClose = () => {
    Close()
    setErrors({});
    Addpatient.patientId = "";
    Addpatient.oneToOneObserver = "";
    Addpatient.patientName = "";
    Addpatient.prescribedbyName = "";
    Addpatient.patientIncharge = "";
    Addpatient.prescribedby = "";
    Addpatient.oneToOneObserverName = "";
    Addpatient.patientInchargeName = "";
    Addpatient.startTime = "";
    Addpatient.endTime = "";
  }

  
  const fetchPatientData = async () => {
    const date = selectedDate.getDate().toString().padStart(2, '0');
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const { header1 } = authorize();
    HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
        .then((response) => {
            if (response.data.message.code === successCode) {
                setPatientDate(response.data.data)
            } else {
                setPatientDate([""]);
            }
        })
  }
  useEffect(() => {
      fetchPatientData();
  }, []);
  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    try {

        let newErrors = {
            patientId: !Addpatient.patientId,
            startTime: !Addpatient.startTime,
            endTime: !Addpatient.endTime,
            prescribedby: !Addpatient.prescribedby,
            patientIncharge: !Addpatient.patientIncharge,
        };
        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error);
        if (hasErrors) {
            return;
        }
        const { header1 } = authorize();
        const bodyData = {
            date: forDate,
            id: "",
            organization,

            pid: Addpatient.patientId,
            shift: shifts?.map((k:any) => ({
                record: k.shift[0].record?.map((l: any) => ({
                    distance: "",
                    endTime: Addpatient.endTime,
                    note: [
                        {
                            note: "",
                            time: ""
                        }
                    ],
                    staffId: Addpatient.prescribedby,
                    startTime: Addpatient.startTime,
                })),
                shiftIncharge: Addpatient.patientIncharge,
                shiftName: "Shift-A",
                shiftTime: "",
            })),
        }
        switch (selectedTab) {
            case "Shift-A":
                bodyData.shift.forEach((shift: any)=> shift.shiftName = "Shift-A");
                break;
            case "Shift-B":
                bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-B");
                break;
            case "Shift-C":
            default:
                bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-C");
                break;
        }
        const response = await axios.post(`${baseURL}/oneToOne/register`, bodyData, { headers: header1 });

        if (response.data.message.code === successCode) {
            dispatch({ type: 'SET_FORM_VALUES', payload: response.data.data });
            toast.success(response.data.message.description);
            Close();
            fetchPatientData();
        } else {
            toast.error("Login failed: " + response.data.message.description);
        }
    } catch (error) {
        toast.error("An error occurred during register.");
    }
  };
  return (
  <>
    <div>
    <Modal isOpen={IsOpen} centered size='lg'>
      <div className="d-flex align-items-center justify-content-center vh-90">
          <div className='row'>
            <div className='container col-md-12'>
              <ModalHeader toggle={handleClose}>
                  Add 1:1 Routine
              </ModalHeader>
              <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto', display: "flex", justifyContent: "center" }}>
                <div className="row w-100" style={{ marginTop: '10px' }}>
                  <div className='col-md-12 mb-2'>Select Patient Name</div>
                  <div className='col-md-12 mb-2'>
                    <Autocomplete
                      id='patientId'
                      options={patientDropdown}
                      getOptionLabel={(option) => `${option.name}`}
                      value={patientDropdown?.find((patient: any) => patient.id === Addpatient.patientId) || null}
                      onChange={(event, value) => {
                          setAddpatient({ ...Addpatient, patientId: value ? value.id : null });
                          setErrors({ ...errors, patientId: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Patient Name'
                          variant="outlined"
                          error={!!errors.patientId}
                          helperText={errors.patientId ? 'Patient name is required' : ''}
                        />
                      )}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>StartTime</div>
                  <div className='col-md-3 mb-2'>EndTime</div>
                  <div className='col-md-6 mb-2'>Prescribed By</div>
                  <div className='col-md-3 mb-2'>
                    <input
                      type="time"
                      autoComplete="off"
                      value={Addpatient.startTime}
                      onChange={handleInputChangeadd}
                      id="startTime"
                      style={{ border: errors.startTime ? '2px solid red' : '1px solid #c6c6c6', height: '57px' }}
                      placeholder=" "
                      min="00:00"
                      max="23:59"
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <input
                      type="time"
                      autoComplete="off"
                      value={Addpatient.endTime}
                      onChange={handleInputChangeadd}
                      id="endTime"
                      style={{ border: errors.endTime ? '2px solid red' : '1px solid #c6c6c6', height: '57px' }}
                      placeholder=" "
                      min="00:00"
                      max="23:59"
                    />
                  </div>
                  <div className='col-md-6 mb-2'>
                    <Autocomplete
                      id='prescribedby'
                      options={staffDropdown}
                      getOptionLabel={(option) => `${option.name}`}
                      value={staffDropdown?.find((staff: any) => staff.id === Addpatient.prescribedby) || null}
                      onChange={(event, value) => handleAutocompleteChange('prescribedby', value, 'prescribedbyName')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Staff Name'
                          variant="outlined"
                          error={!!errors.prescribedby}
                          helperText={errors.prescribedby ? 'Prescribed by is required' : ''}
                        />
                      )}
                    />
                  </div>
                  <div className='col-md-6 mb-2'>Patient Incharge</div>
                  <div className='col-md-6 mb-2'></div>
                  <div className='col-md-6 mb-2'>
                    <Autocomplete
                      id='patientIncharge'
                      options={staffDropdown}
                      getOptionLabel={(option) => `${option.name}`}
                      value={staffDropdown?.find((staff: any) => staff.id === Addpatient.patientIncharge) || null}
                      onChange={(event, value) => handleAutocompleteChange('patientIncharge', value, 'patientInchargeName')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Staff Name'
                          variant="outlined"
                          error={!!errors.patientIncharge}
                          helperText={errors.patientIncharge ? 'Patient incharge is required' : ''}
                        />
                      )}
                    />
                  </div>
                  <div className='col-md-6 mb-2'>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="">
                <div className="d-flex gap-3 justify-content-center">
                  <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose} ></Button>
                  <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveClick}></Button>
                </div>
              </ModalFooter>
            </div>
          </div>
      </div>
    </Modal>
    </div>
  </>
  )
}

export default AddOneToOne