import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPatient,
  getAllBedAssign,
  getAllBed,
} from "../../slices/thunk";
import { DateValidationError } from '@mui/x-date-pickers';
import { FaSearch } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import dischargeIcon from '../../assets/images/bed (2).png';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@mui/material";
import { baseURL } from "../../configuration/url";
import { BrowserMultiFormatReader } from "@zxing/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import axios from 'axios';
import { toast } from "react-toastify";
import { authorize, formatDate1 } from '../../helpers/common';
import Loader from "../../components/loader/Loader";
import { Paginator } from 'primereact/paginator'
import './newPatient.css'
import "../bedAssign/bedassign.css";
import dayjs from "dayjs";
import AdmitPatientEditModal from "./admitPatientEditModal";
import TransferModal from "./transferModal";
const Patient: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [selectPatientId, setSelectPatientId] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const { loading, totalElements } = useSelector((state: any) => state.Patient);
  const { organization } = useSelector((state: any) => state.Login);
  const [bedAssignDialog, setBedAssignDialog] = useState(false);
  const [bedClick, setBedClick] = useState(false)
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const [bedId, setBedId] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState('')
  const [scanning, setScanning] = useState(false);
  let [dischargeTransferId, setDischargeTransferId] = useState<string | null>(null);
  let [bedSelected, setBedSelected] = useState<string | null>(null);
  const codeReader = new BrowserMultiFormatReader();
  const videoRef = useRef(null);
  const [options, setOptions] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [admitD, setAdmitD] = useState<boolean>(false);
  const [reason, setReason] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFieldError, setDateFieldError] = React.useState<string>('');
  const [error, setError] = React.useState<DateValidationError | null>(null);
  const [admitDate, setAdmitDate] = React.useState<dayjs.Dayjs | null>(null);
  let [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    ssn: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    mrNumber: "",
    email: "",
    beaconDevice1: [],
    gender: "",
    country: "",
    profile: ""
  });
  useEffect(() => {
    getAllPatient(dispatch, organization, searchTerm ? 0 : currentPage, searchTerm ? searchTerm : '');
  }, [dispatch, organization, searchTerm]);
  const fetchPatientsandBedAssign = async () => {
    const { header1 } = authorize();
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 }
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);
  const { patientData } = useSelector((state: any) => state.Patient);

  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);

  useEffect(() => {
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getPatientsBeacon?organization=${organization}`, { headers: header1 });
        setOptions(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchPatientsandBedAssign();
  }, [admitD]);

  const handleBedClick = (selectedBed: any) => {
    setBedSelected(selectedBed.roomNo + "-" + selectedBed.bedNo);
    const bedAssignId = selectedBed.id || " ";
    setBedId(bedAssignId);
    setBedClick(true)
  };

  const handleDioCancel = () => {
    setBedAssignDialog(false);
    setBedClick(false);
    setAdmitD(false)
    setAdmitDate(null);
    setDateFieldError('');
    setError(null);
  }
  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            let newDeviceId = "";
            const result1 = result.getText();
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 15) {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId;
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId.slice(6, 23);
              }
              setShow(false);
              setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
            if (scannedOption) {
              setDeviceId(scannedOption.deviceId)
              const modal = document.getElementById("exampleModal");
              if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
              }
            } else {
              setDeviceId("");
              toast.error("Scanned device ID not found in the options.");
            }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };
  const [show, setShow] = useState(false);

  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };

  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }

  const handleQrClick = () => {
    setShow(true);
    setScanning(!scanning);
  }


  const bedsByNumber = patientAndBedAssign.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});


  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }
  const [patientname, setpatientname] = useState('');
  const [roomNo, setRoomNo] = useState('');

  const handleTransfer = (patient: any) => {
    const fullname = `${patient.basicDetails[0].name[0].given || ''} ${patient.basicDetails[0].name[0].family || ''}`;
    setpatientname(fullname);
    setRoomNo(patient.assignedBed ? patient.assignedBed : "")
    setBedAssignDialog(true);
    setDeviceId(patient.beaconDevice)
    setAdmitDate(null);
    setReason("");
    setDischargeTransferId("1");
    setSelectPatientId(patient.id);
  }

  const handleDis = (patient: any) => {
    const fullname = `${patient.basicDetails[0].name[0].given || ''} ${patient.basicDetails[0].name[0].family || ''}`;
    setpatientname(fullname);
    setRoomNo(patient.assignedBed ? patient.assignedBed : "")
    setBedAssignDialog(true);
    setDeviceId("");
    setAdmitDate(null);
    setReason("");
    setDischargeTransferId("2");
    setSelectPatientId(patient.id);
  }
  
  const formatDateForInput = (dateString: any) => {
    if (!dateString || dateString.length !== 8) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}-${month}-${day}`;
  };
  const handleClick = (selectedPatient: any) => {
    if (selectedPatient) {
      const fullName = `${selectedPatient.basicDetails[0].name[0].given || ''} ${selectedPatient.basicDetails[0].name[0].family || ''}`;
      setpatientname(fullName);
      const patientId = selectedPatient.id || "";
      setSelectPatientId(patientId);
      setDeviceId(selectedPatient.beaconDevice)
      const basicDetails = selectedPatient.basicDetails[0];
      const address = selectedPatient.contact[0]?.address[0];
      setFormData({
        firstName: basicDetails.name[0]?.given || "",
        middleName: basicDetails.name[0]?.use || "",
        lastName: basicDetails.name[0]?.family || "",
        birthDate: formatDateForInput(basicDetails.birthDate) || "",
        ssn: basicDetails.ssn || "",
        addressLine1: address?.addressLine1 || "",
        addressLine2: address?.addressLine2 || "",
        city: address?.city || "",
        state: address?.state || "",
        postalCode: address?.postalCode || "",
        mrNumber: basicDetails.mrNumber || "",
        email: selectedPatient.email || "",
        beaconDevice1: selectedPatient.beaconDevice1 || [],
        gender: basicDetails.gender || "",
        country: address?.country || "",
        profile: basicDetails.profile || "",
      });
      setEditModal(true);
    } else {
      console.error("Invalid patient data:", selectedPatient);
    }
  };

  return (
    <div className="container m5 p3" style={{ width: '90%' }}>
      {loading && <Loader />}
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-8 d-flex align-items-center">
          <h5>Active Patient List</h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={handleSearch}
            />
            <FaSearch className="search-icon mt-1" />
          </div>

        </div>
        <div className="row">
          <div className="col-md-3">

          </div>
        </div>
        <table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead>
            <tr>
              <th scope="col" style={{ width: '13px' }} className="text-center table-data">S.No</th>
              <th scope="col" className="text-center table-data">Patient Name</th>
              <th scope="col" className="text-center table-data">SSN</th>
              <th scope="col" className="text-center table-data">Date Of Birth</th>
              <th scope="col" className="text-center table-data">Beacon Device</th>
              <th scope="col" className="text-center table-data">Room No - Bed No</th>
              <th scope="col" className="text-center table-data">Action</th>
            </tr>
          </thead>
          <tbody>
            {patientData?.length > 0 ? patientData?.map((patient: any, index: number) => (
              <tr key={index}>
                <td className="text text-center">{currentPage * 10 + index + 1}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleClick(patient)
                  }
                  className="text"
                >
                  {patient.basicDetails[0].name[0].given}{" "}
                  {patient.basicDetails[0].name[0].family}
                </td>
                <td className="text">{'***' + patient.basicDetails[0].ssn.slice(-4)}</td>
                <td className="text">{formatDate1(patient.basicDetails[0].birthDate)}</td>
                <td className="text">{patient.beaconDevice}</td>
                <td className="text">{patient.assignedBed}</td>
                <td className="text d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleClick(patient)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Transfer" arrow>
                    <img
                      src={dischargeIcon}
                      alt="Discharge Icon"
                      className="text-danger"
                      style={{ cursor: "pointer", width: '16px', height: '18px' }}
                      onClick={() => {
                        handleTransfer(patient)
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Discharge" arrow>
                    <FontAwesomeIcon
                      icon={faBan}
                      className="text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDis(patient)
                      }}
                    />
                  </Tooltip>
                </td>
              </tr>
            )) : 
            <tr>
              <td colSpan={7} className="text-center">
                <h5>No Active Patients found</h5>
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>
      <div className="pagination-container" >
        <div className="d-flex justify-content-center">
          <Paginator
            first={currentPage * 10}
            rows={10}
            totalRecords={totalElements}
            onPageChange={(e: any) => {
              setCurrentPage(e.page)
              getAllPatient(dispatch, organization, e.page, searchTerm ? searchTerm : '')
            }}
            currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
          />
        </div>
      </div>
      <AdmitPatientEditModal 
        isOpen={editModal} 
        Close={setEditModal} 
        patientname={patientname} 
        formData={formData} 
        setFormData={setFormData} 
        deviceId={deviceId} 
        setDeviceId={setDeviceId} 
        options={options} 
        handleQrClick={handleQrClick} 
        selectPatientId={selectPatientId} 
        currentPage={currentPage} 
      />
      <TransferModal 
        IsOpen={bedAssignDialog} 
        dischargeTransferId={dischargeTransferId} 
        Close={handleDioCancel} 
        handleQrClick={handleQrClick} 
        patientname={patientname} 
        error={error} 
        setError={setError} 
        admitDate={admitDate} 
        deviceId={deviceId} 
        setDeviceId={setDeviceId} 
        setAdmitDate={setAdmitDate} 
        dateFieldError={dateFieldError} 
        setDateFieldError={setDateFieldError} 
        reason={reason} 
        setReason={setReason} 
        options={options} 
        bedClick={bedClick} 
        bedSelected={bedSelected} 
        roomNo={roomNo} 
        admitD={admitD} 
        setAdmitD={setAdmitD} 
        bedId={bedId} 
        bedsByNumber={bedsByNumber} 
        currentPage={currentPage} 
        setBedAssignDialog={setBedAssignDialog} 
        setBedClick={setBedClick} 
        selectPatientId={selectPatientId} 
        handleBedClick={handleBedClick} 
      />
      <Dialog open={show} onClose={handleClose1}>
        <DialogTitle>Scanning</DialogTitle>
        <DialogContent>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </DialogContent>
        <div className="d-flex gap-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
          <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Patient;