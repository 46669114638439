import { createSlice,PayloadAction } from "@reduxjs/toolkit";

interface Patient{
  id:string;
}
interface PatientDetailsState{
  loading: boolean,
  patientData: Patient[],
  patientDropdown: [],
  allPatientData: Patient[],
  allOrgData: Patient[],
  totalElements: number,
  isOpen: boolean,
  errorMsg: string,
}
const initialState :PatientDetailsState={
  loading: false,
  patientData: [],
  totalElements: 0,
  allPatientData: [],
  allOrgData: [],
  isOpen: false,
  errorMsg: "",
  patientDropdown: [],
};

const PatientCreationSlice = createSlice({
  name: "patientData",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },

    setIsLoadingFalse(state) {
      state.loading = false;
    },
    setErrorMessage(state, action:PayloadAction<string>) {
      state.loading = false;
      state.isOpen = true;
      state.errorMsg = action.payload;
    },

    closeErrorPopup(state) {
      state.isOpen = false;
      state.errorMsg = "";
    },

    getPatientSuccess(state, action:PayloadAction<Patient[]>) {
      state.loading = false;
      state.patientData = action.payload;
    },
    getPatientDropdownSuccess(state, action:PayloadAction<[]>) {
      state.loading = false;
      state.patientDropdown = action.payload;
    },

    getTotalElementsSuccess (state, action) {
      state.totalElements = action.payload
    },

    getPatientgetOrgSuccess(state, action:PayloadAction<Patient[]>) {
      state.loading = false;
      state.allPatientData = action.payload;
    },
    getPatientgetAllOrgSuccess(state, action:PayloadAction<Patient[]>) {
      state.loading = false;
      state.allOrgData = action.payload;
    },
    
  },
});

export const {
  isLoading,
  setIsLoadingFalse,
  setErrorMessage,
  closeErrorPopup,
  getPatientSuccess,
  getTotalElementsSuccess,
  getPatientgetOrgSuccess,
  getPatientgetAllOrgSuccess,
  getPatientDropdownSuccess,
} = PatientCreationSlice.actions;

export default PatientCreationSlice.reducer;