import axios from "axios"
import { baseURL, successCode } from "../../configuration/url"
import { setDevices, setOrgName, setOrgTimeZone, setPatientData } from "./reducer"


export const getDevices = async (dispatch: any, organization: string) => {
    try {
      const response = await axios.get(`${baseURL}/siren/getAllDevices?organization=${organization}`)
      if (response.data.devices[0].status) {
        dispatch(setDevices(response.data.devices))
      } else (
        dispatch(setDevices([]))
      )      
    } catch (error: any) {
      console.error("API Error:",error)
    }
}


export const getApi = async(dispatch: any, organization: string) => {
  try {
    const response = await axios.get(
        `${baseURL}/patient/getPatientDataForSlide?organization=${organization}`
      );
      if (response.data.message.code === successCode) {
        dispatch(setPatientData(response.data.data));
        dispatch(setOrgName(response.data.data[0].organization))
        localStorage.setItem('LOrgname',response.data.data[0].organization)
        localStorage.setItem('LTimezone',response.data.data[0].timeZone)
        dispatch(setOrgTimeZone(response.data.data[0].timeZone))
      } else {
        dispatch(setPatientData([]))
      }
  } catch (error: any) {
    console.error("API Error:", error)
  }
}