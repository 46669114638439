import React, { useEffect, useRef, useState } from "react";
import LogoImg from "../../assets/images/mettlerTitle.png";
import Sos from "../../assets/images/sos.png";
import siren from "../../assets/images/siren-on1.png";
import Triggered from "../../assets/images/sosTriggered.png";
import { Avatar, Box, IconButton, Modal, Tooltip } from "@mui/material";
import Card from "../../components/card/StaffCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { baseURL, successCode } from "../../configuration/url";
import { FaRssSquare, FaTimesCircle } from "react-icons/fa";
import { RiAlarmWarningFill } from "react-icons/ri";
import "./staffDisplay.css";
import Slide from "./customslider";
import { getAllStaffLS } from "../../slices/thunk";
import { setCurrentDateTime, setOrgName } from "../../slices/LargeScreen/reducer";  
const StaffDisplay = () => {
  const dispatch = useDispatch()
  const { staffData1 } = useSelector((state: any) => state.Staff);
  const { organization } = useSelector((state: any) => state.Login);
  const staffData = staffData1?.staffData;
  const [orgTimeZone, setOrgTimeZone] = useState(staffData1?.timeZone)
  const { AllpatientData } = useSelector((state: any) => state.PSConfig)

  const fullscreenRef = useRef<HTMLDivElement>(null);

  // const handleFullscreen = (event: any) => {
  //   const elem = fullscreenRef.current;
  //   if (elem && !open) {
  //     const requestFullscreen =
  //       elem.requestFullscreen ||
  //       (elem as any).mozRequestFullScreen ||
  //       (elem as any).webkitRequestFullscreen ||
  //       (elem as any).msRequestFullscreen;
  //     if (requestFullscreen) {
  //       requestFullscreen.call(elem);
  //     } else {
  //       console.error("Fullscreen is not supported");
  //     }
  //   }
  // };
  //   const [patientData, setPatientData] = useState([]);
 const [staffName1, setStaffName1] = useState<any>()
  const [alarmHistory, setAlarmHistory] = useState([]);
  const [alarm, setAlarm] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);
  const [open, setOpen] = useState(false);
  const handleTrigger = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllStaffLS(dispatch, organization)
  }, [organization, AllpatientData])
  const [available, setAvailable] = useState("");
  const [staffName, setStaffName] = useState<any>([]);
  const [location, setLocation] = useState("");
  const [occupied, setOccupied] = useState("");
  const [devices, setDevices] = useState([])
  useEffect(() => {
    const getbedCount = async () => {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByAvailable/${organization}`
      );
      const res = await axios.get(
        `${baseURL}/Q15Bed/getByOccupied/${organization}`
      );
      setAvailable(response.data.data.length);
      setOccupied(res.data.data.length);
    };
    getbedCount();
  }, [organization]);

  useEffect(() => {
    const getAlarm = async () => {
      try {
        const response = await axios.get(`${baseURL}/alarm/get?organization=${organization}`);
        if (response.data && response.data.alarm) {
          setAlarm(response.data.alarm);
          setStaffName1(response.data.staffName)
          setToggleValue(response.data.alarm);
          handleTrigger()
          getHistory()
        } else {
          setAlarm(response.data.alarm);
          setStaffName('')
          setToggleValue(response.data.alarm);
        }
      } catch (error) {
        console.error("Error fetching alarm:", error);
      }
    };
    getAlarm();
    const intervalId = setInterval(getAlarm, 2000);
    return () => clearInterval(intervalId);
  }, []);
  // useEffect(() => {
  //   const getGateway = async () => {
  //     if (staffData && staffData.length > 0) {
  //       for (const staff of staffData) {
  //         // Only call the API if sosDevice is not an empty string
  //         if (staff.sosDevice !== "") {
  //           console.log('staff',staff.sosDevice)
  //           const response = await axios.get(`${baseURL}/alarm/getSosDevicePressedStaffAndLocation?deviceId=${staff.sosDevice}&organization=${organization}`);

  //           if (response?.data.message.code === successCode) {
  //             const res = await axios.post(`${baseURL}/siren/siren-on?organization=${organization}`);
  //             console.log('siren-on', res.data);
  //             setStaffName(response.data.data.staffName || "");
  //             setLocation(response.data.data.location || "");
  //           }
  //         }
  //       }
  //     }
  //   }
  //   getGateway();
  //   const intervalId = setInterval(getGateway, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);

  let intervalId: NodeJS.Timeout;  // Declare intervalId outside of getGateway

useEffect(() => {
  
  const getGateway = async () => {
    if (open) {
      if (intervalId) clearInterval(intervalId); // Only clear if intervalId exists
      return
    }
    
    if (staffData && staffData.length > 0) {
      const Devices = staffData
        .map((staff: any) => staff.sosDevice)
        .filter((device: string | undefined) => device && device.trim() !== "");

      if (Devices.length > 0) {
        const devicesFormatted = `${Devices.join(",")}`;
        try {
          const response = await axios.get(`${baseURL}/alarm/getSosDevicePressedStaffAndLocation?deviceIds=${devicesFormatted}&organization=${organization}`);
          if (response?.data.message.code === successCode) {
            if (response.data.data.length > 0) {
              setStaffName(response.data.data || "");
              setLocation(response.data.data[0].location || "");              
            }
          }
        } catch (error) {
          console.error(`Error with device(s) ${devicesFormatted}:`, error);
        }
      } else {
        console.log('No valid devices found');
      }
    } else {
      console.log('staffData is empty or undefined');
    }
  };

  // Call the getGateway function immediately
  // getGateway();

  // Set interval to call getGateway every 2 seconds
  intervalId = setInterval(getGateway, 2000);

  // Clean up interval when component unmounts or when 'open' changes
  return () => clearInterval(intervalId);

}, [open, staffData, organization]);  // Add all relevant dependencies to the dependency array


  const getHistory = async () => {
    const response = await axios.get(
      `${baseURL}/alarm/getAlarmRecord?organization=${organization}`
    );
    if (response.data.message.code === successCode) {
      setAlarmHistory(response.data.data);
    } else {
      setAlarmHistory([]);
    }
  };
  useEffect(() => {
    getHistory();
  }, [organization]);

  

  const handleToggleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    setToggleValue(newValue);
    try {
      const body = {
        alarm: {
          alarm: false
        }
      };
      const response = await axios.post(`${baseURL}/alarm/register`, body);
      if (response.data.message.code === successCode) {
        const res = await axios.delete(`${baseURL}/beacon/deleteKBeaconDevice?deviceId=dzfvsdfgs`)
        if (res.data.message.code === successCode) {
          console.log(response.data,"response.data")
          handleClose();
        }
      }
    } catch (error) {
      console.error("Error toggling switch:", error);
    }
  };

  useEffect(() => {
    const getDevices = async () => {
      const response = await axios.get(`${baseURL}/siren/getAllDevices?organization=${organization}`)
      if (response.data.devices[0].status) {
        setDevices(response.data.devices)
      } else (
        setDevices([])
      )
    }
    getDevices()
  }, [organization])

  // const handleToggleChangeWrapper = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const newValue = event.target.checked;
  //   await handleToggleChange(event);
  //   try {
  //     const responseData = await axios.post('http://47.32.254.89:7000/api/alarm/off');
  //     if (responseData.data.success) {
  //     } else {
  //       console.error('response else from tua', responseData.data)
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };
  
  useEffect(() => {
    if (toggleValue) return
    console.log('first 123456789')
    handleClose()
  }, [toggleValue])

  const lslides = [];
  const itemsPerSlide = 16;
  for (let i = 0; i < staffData?.length; i += itemsPerSlide) {
    const slideData = staffData?.slice(i, i + itemsPerSlide);
    const slide = (
      <div key={i}>
        <div style={{ display: "flex", flexDirection: "column", height: "640px" }}>
          {[0, 1, 2, 3].map((rowIndex) => (
            <div key={rowIndex} style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", height: "33%", width: "100%" }}>
              {slideData.slice(rowIndex * 4, (rowIndex + 1) * 4).map((item: any, index: any) => (
                <div key={index} className="mb-4 g-2" style={{ flex: "1", display: "flex", justifyContent: "flex-end"}} >
                  <Card
                    // style={{ color: '#f8b19570'}}
                    staffRole={item.staffRole}
                    patientName={item.staffName}
                    role={item.staffRole}
                    sos={item.sosDevice}
                    heartRate={item.heartRate}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
    lslides.push(slide);
  }

  return (
    <div ref={fullscreenRef}>
      {/* <div
        className="row justify-content-around align-items-center"
        style={{ height: "50px", backgroundColor: primarybg }}
        onClick={handleFullscreen}
      >
        <div className="col-md-4 d-flex flex-column align-items-center">
          <p style={{ color: "#fff", fontWeight: "normal", margin: "0 0 0 10px" }}>
            Organization: {staffData1?.organization}
          </p>
          <p style={{ color: "#fff", fontWeight: "normal", margin: "0 0 0 10px" }}>
            Date/Time: {currentDateTime}
          </p>
        </div>
        <div className="col-md-4 d-flex justify-content-center align-items-center">
          <img src={LogoImg} alt="Logo" className="logo-img" />
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-1 d-flex align-items-center">
          <div className="">
            <Box>
              <Tooltip title="Account settings">
                <IconButton size="small">
                  <Avatar
                    sx={{ width: 32, height: 32, backgroundColor: "#9F9FA2" }}
                  ></Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        </div>
      </div> */}
      <div className="row mx-0" style={{ marginTop: '60px' }}>
        <div className="col-md-9" style={{ backgroundColor: "#0A2A7C" }}>
          <div >
            <Slide>
              {lslides}
            </Slide>
          </div>
        </div>
        <div className="col-md-3 d-flex flex-column align-items-center" style={{ backgroundColor: "#4E6BB2" }}>
          <div className="row ">
            <div className="d-flex justify-content-center mt-2">
              {alarm ? (
                <img
                  src={Triggered}
                  alt="Logo"
                  className="logo-img"
                  style={{ width: "120px" }}
                  onClick={handleTrigger}
                />
              ) : (
                <img
                  src={Sos}
                  alt="Logo"
                  className="logo-img"
                  style={{ width: "120px" }}
                />
              )}
            </div>
          </div>
          <div className="row mt-2" style={{
            width: "80%",
            borderRadius: "5px",
            background:
              "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
            borderColor: "white",
            borderWidth: 0.5,
            borderStyle: "solid",
          }}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontStyle: "revert-layer",
                    borderRadius: "5px",
                    alignItems: 'center',
                    justifyContent: "start",
                    textAlign: "center",
                    paddingTop: "4px"
                  }}
                >
                  Alarms
                </p><hr
                  style={{
                    borderTopColor: "#ffffff",
                    borderTopWidth: 0.5,
                  }}
                /></div>
            </div>
            <div className="">
              {alarmHistory.map((item: any, index: any) => (
                <div
                  key={item.id}
                  className="mb-2 justify-content-around align-items-center"
                  style={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 5,
                    backgroundColor: "#fff",
                  }}
                >

                  <p style={{ fontWeight: "bold", marginTop: "12px", fontSize: "12px" }}>
                    {item.triggeredDate + " " + item.triggeredTime}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginTop: "12px",
                      fontSize: "11px",
                    }}
                  >
                    {item.triggeredStaffId}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="row mt-2" style={{
            width: "80%",
            borderRadius: "5px",
            background:
              "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
            borderColor: "white",
            borderWidth: 0.5,
            borderStyle: "solid",
          }}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontStyle: "revert-layer",
                    borderRadius: "5px",
                    textAlign: "center",
                    alignItems: 'center',
                    justifyContent: "start",
                    paddingTop: "4px"
                  }}
                >
                  All Devices
                </p><hr
                  style={{
                    borderTopColor: "#ffffff",
                    borderTopWidth: 0.5,
                  }}
                /></div>
              <div className="col-md-1"></div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", marginBottom: "20px", gap: "4px" }} >
              {devices && devices.map((item: any, index: any) => 
                {if (item.data.msg !== 'token invalid') {
                  return (
                    <div key={index} style={{ backgroundColor: '#fff', alignItems: "flex-start", height: "40px", display: "flex", justifyContent: "center", padding: "0px 0px 0px 4px", borderRadius: "4px", flexDirection: "column" }}>
                      <img
                        src={siren}
                        alt={`Siren ${index + 1}`}
                        className="logo-img"
                        style={{ width: "15px", height: "15px" }}
                      />
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <p style={{ color: "#000", margin: 0, fontSize: '9px' }}>{item?.data?.result?.devices[0]?.name}</p>
                        <p style={{ color: item?.data?.result?.devices[0]?.online ? "green" : "red", margin: 0, fontSize: '9px' }}>{item?.data?.result && item?.data?.result?.devices[0]?.online ? 'Online' : 'Offline'}</p>
                      </div>
                    </div>
                  )

                } else {
                  return(<div>
                    <p style={{ color: "white", margin: 0, fontSize: '14px' }}>
                      No devices
                    </p>
                  </div>)
                }}
              
              )}
            </div>
          </div>
          <div className="row mt-2 mb-2" style={{
            width: "80%",
            borderRadius: "5px",
            background:
              "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
            borderColor: "white",
            borderWidth: 0.5,
            borderStyle: "solid",
          }}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    textAlign: "center",
                    alignItems: 'center',
                    justifyContent: "start",
                    paddingTop: "4px"
                  }}
                >
                  Staff Statistics
                </p><hr
                  style={{
                    borderTopColor: "#ffffff",
                    borderTopWidth: 0.5,
                  }}
                /></div>
            </div>
            <div className="row px-3">
              <div
                className="d-flex align-items-start justify-content-center"
                style={{ flexDirection: "column", width: "65%" }}
              >
                <p
                  style={{
                    marginBottom: "0rem",
                    color: "white",
                    fontWeight: "500",
                    fontSize: '14px'
                  }}
                >
                  Total SOS Count:
                </p>
                <p style={{ color: "#2CDB72", fontWeight: "500", fontSize: '14px' }}>
                  {staffData1?.sosDeviceTotalCount}
                </p>
              </div>
              <IconButton
                style={{
                  width: "30%",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <FaRssSquare size={"25px"} color="#2CDB72" />
              </IconButton>
            </div>
            <div className="row px-3">
              <div
                className="d-flex align-items-start justify-content-center"
                style={{ flexDirection: "column", width: "65%" }}
              >
                <p
                  style={{
                    marginBottom: "0rem",
                    color: "white",
                    fontWeight: "500",
                    fontSize: '14px'
                  }}
                >
                  Available SOS:
                </p>
                <p style={{ color: "#CCD9A8", fontWeight: "500", fontSize: '14px' }}>
                  {staffData1?.availableSosCount}
                </p>
              </div>
              <IconButton
                style={{
                  width: "30%",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <FaRssSquare size={"25px"} color="#CCD9A8" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {toggleValue && (
        <Modal
          open={toggleValue}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              width: 600,
              height: 350,
              borderRadius: 8,
              padding: 10,
              display: "flex",
              flexDirection: "column",
              borderBottom: "30px solid #F04349",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <IconButton style={{ color: "white" }} onClick={handleClose}>
                <FaTimesCircle size={17} />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RiAlarmWarningFill style={{ color: "red", fontSize: 50 }} />
            </div>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center",
                color: "#fff",
              }}
            >
              Emergency
            </p>
            {Array.isArray(staffName) ? staffName?.map((item: any) => (<p
              style={{
                fontFamily: "Poppins",
                fontSize: 14,
                textAlign: "center",
                color: "white",
              }}
            >
              {item?.staffName ? item?.staffName : 'N/A'} called for help - Go to   Location: {item?.location}
            </p>))
            : 
            (<>
            <p 
            style={{
                fontFamily: "Poppins",
                fontSize: 14,
                textAlign: "center",
                color: "white",
              }}>
              {staffName1}
            </p>
            <p
            style={{
              fontFamily: "Poppins",
              fontSize: 10,
              textAlign: "center",
              color: "white",
            }}
          >
            called for help. Go to Location {location}
          </p>
            </>)}
            
            <div
              style={{
                display: "flex",
                justifyContent: 'center',
              }}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={toggleValue}
                  onChange={handleToggleChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default StaffDisplay;