import { Bed } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogContentText, IconButton, ListItem, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react'
import { FaQrcode } from 'react-icons/fa';
import { List } from 'reactstrap';
import { Button } from "primereact/button";
import PatientBed from './patientBed';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { authorize, formatDateToYYYYMMDD } from '../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import { getAllOrgPatient } from '../../slices/thunk';
import { toast } from 'react-toastify';
import axios from 'axios';
interface PatientModalProps {
  bedAssignDialog: any
  handleDioCancel: () => void 
  selectedPatientName: any
  error:any
  setError: any
  admitDate: any
  setAdmitDate: any
  dateFieldError: string
  setDateFieldError: any
  reason: any
  setReason: any
  deviceId: any
  setDeviceId: any
  handleQrClick: any
  options: any
  bedSelected:any
  admitD: any
  setAdmitD: any
  bedId: any
  handleBedClick:any
  bedsByNumber: any
  selectPatientId: any
  currentPage: any
}
const PatientAdmitModal: React.FC<PatientModalProps> = ({bedAssignDialog, handleDioCancel, selectedPatientName, error, setError, admitDate, setAdmitDate, dateFieldError, setDateFieldError, reason, setReason, deviceId, setDeviceId, handleQrClick, options, bedSelected, admitD, setAdmitD, bedId, handleBedClick, bedsByNumber, selectPatientId, currentPage}) => {
    const dispatch = useDispatch<any>()
    const { organization, userData } = useSelector((state: any) => state.Login);
    const userId = userData?.userDetail.id;
    const errorMessage = React.useMemo(() => {
      if (dateFieldError) return dateFieldError;
      switch (error) {
        case 'maxDate':
        case 'minDate':
          return 'Please select a date in the first quarter of 2022';
        case 'invalidDate':
          return 'Your date is not valid';
        default:
          return '';
      }
    }, [error, dateFieldError]);
    
    const handleSave = async () => {
      if (!admitDate) {
        setDateFieldError('Date field cannot be empty.');
        return;
      }
      try {
        const formattedDate = formatDateToYYYYMMDD(admitDate);
        const requestBody = {
          bedId: bedId,
          pid: selectPatientId,
          assignedBy: userId,
          admitDate: formattedDate,
          admitReason: reason,
          deviceId: deviceId
        };
        const { header1 } = authorize();
        const response = await axios.post(
          `${baseURL}/Q15Bed/assign`,
          requestBody, { headers: header1 }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          getAllOrgPatient(dispatch, organization, currentPage, '');
          toast.success(response.data.message.description)
          handleDioCancel()
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("API Request Error:", error);
      }
    }
    
    return (
    <div>
    <Dialog maxWidth={'md'} PaperProps={{ sx: { width: '72%', maxWidth: '72%', position: 'absolute', height: '500px', top: '40px', overflow: 'hidden' } }}
      open={bedAssignDialog}
      onClose={() => handleDioCancel()}
    >
      <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 10px", alignItems: "center", gap: "10px", top: 0 }}>
        <div style={{ fontWeight: 600 }}>
          <h5>Admit</h5>
        </div>
        <div>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleDioCancel}></button>
        </div>
      </div>

      <DialogContentText >
        <DialogContent style={{ padding: '20px', background: '#F8FAFB', overflowY: 'auto', height: '400px' }}>
          <div>
            <>
              <div className="row w-100 mb-3">
                <div className="col-md-12">
                  <TextField id="Reason" label="Patient Name" variant="outlined" value={selectedPatientName} fullWidth />
                </div>
              </div>
              <div className="row w-100 ">
                <div className='col-md-6 mb-2' >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box >
                      <DatePicker
                        sx={{ width: "100%" }}
                        defaultValue={dayjs('2022-07-17')}
                        onError={(newError) => setError(newError)}
                        label={'Admit Date'}
                        value={admitDate}
                        onChange={(newValue) => {
                          setAdmitDate(newValue);
                          if (dateFieldError) setDateFieldError('')
                        }}
                        slotProps={{
                          textField: {
                            helperText: errorMessage,
                            error: Boolean(dateFieldError || error),
                          },
                        }}
                      />
                    </Box>
                  </LocalizationProvider>
                </div>
                <div className='col-md-6 mb-2'>
                  <TextField id="Reason" label="Admit Reason" variant="outlined" value={reason} fullWidth onChange={(e) => setReason(e.target.value)} />
                </div>
              </div>
              <div className="row w-100 ">
                <div className='col-md-6 mb-2' style={{ textAlign: 'left' }}>{"Device-Id"}</div>
                <div className='col-md-3 mb-2' ></div>
                <div className='col-md-3 mb-2' ></div>
              </div>
              <div className="row w-100">
                <div className='col-md-6 mb-2'>
                  <List style={{ paddingLeft: '0px' }}>
                    <ListItem disableGutters style={{ paddingTop: '0px' }}>
                      <Select
                        id="device-select"
                        value={deviceId}
                        onChange={(event) => setDeviceId(event.target.value)}
                        fullWidth
                        IconComponent={() => (
                          <FaQrcode
                            className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                            role='button'
                            style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer' }}
                          />
                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: '300px',
                            },
                          },
                        }}
                      >
                        {options.length > 0 ? options?.map((option: any) => (
                          <MenuItem key={option.id} value={option.deviceId}>
                            {option.deviceId}
                          </MenuItem>
                        )) : 
                          <MenuItem>
                            No Devices Data
                          </MenuItem>                      
                        }
                      </Select>
                    </ListItem>
                  </List>
                </div>
                <div className="col-md-6 mb-2" style={{ textAlign: 'end' }}>
                  <TextField
                    label="Room-Bed"
                    type="text"
                    placeholder=''
                    margin="none"
                    fullWidth
                    value={bedSelected ? bedSelected : ""}
                    disabled={true}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => {
                          if (admitD === false) {
                            setAdmitD(true);
                          } else {
                            setAdmitD(false);
                          }
                        }} edge="end">
                          <Bed />
                        </IconButton>
                      )
                    }}
                  />
                </div>
              </div>
            </>
            { admitD && <> <PatientBed admitD={admitD} bedId={bedId} handleBedClick={handleBedClick} bedsByNumber={bedsByNumber} /> </>}
          </div>
        </DialogContent>
      </DialogContentText>
      <div className="d-flex gap-3 pt-2 mb-3 justify-content-center">
        <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
          onClick={handleDioCancel}></Button>
        <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
      </div>
    </Dialog>
    </div>
  )
}

export default PatientAdmitModal