import { Avatar, IconButton } from '@mui/material'
import React from 'react'
import { FaBroadcastTower } from 'react-icons/fa'


interface CardProps {
    staffRole: string
    patientName: string;
    role: string;
    sos: string;
    heartRate: string;
}


const Card: React.FC<CardProps> = ({ patientName, role, sos, heartRate, staffRole }) => {
    return (
        <div className='col-sm-3 d-flex align-items-center justify-content-center flex-column w-100'>
            <div className='row d-flex align-items-center flex-column' style={{ width: '85%', backgroundColor: staffRole === 'visitor' ? '#F8B195' : '#F3F6FF', height: '35%', borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                    <div style={{ marginRight: '5px' }}>
                        <Avatar sx={{ width: 32, height: 32, backgroundColor: '#9F9FA2' }}>
                        </Avatar>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ margin: '0', marginBottom: '0px', fontSize: '12px' }}>{patientName}</p>
                        <p style={{ margin: '0', fontSize: '12px' }}>{role}</p>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-around' style={{ width: '85%', height: '50%', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', backgroundColor: parseInt(heartRate) < 60 ? '#FBF3E3' : parseInt(heartRate) > 100 ? '#FFECEC' : '#fff' }}>
               
                <div className='col-md-4 p-0' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                        <IconButton>
                            <FaBroadcastTower size={'20px'} />
                        </IconButton>
                    </div>
                    {sos &&
                    <div style={{ fontSize: '9px' }}>
                    <p style={{ margin: '0', marginBottom: '-2px' }}>SOS Device:</p>
                    <p >{sos}</p>
                </div>
                    }
                </div>
            </div>
        </div>
    );
}


export default Card;