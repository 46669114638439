import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalFooter,ModalBody, ModalHeader } from "reactstrap";
import * as Constants from "../Constants/Constant";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'primereact/button';
import uploadImage from "../../assets/images/rectangle-59991.svg";
import { updateStaffDetails } from '../../slices/thunk';

interface staffUpdateProps {
  IsOpen: any
  CloseModal: any
  StaffName: any
  formData: any
  setFormData: any
  selectStaffId: any
  currentPage: any
}
const StaffUpdate: React.FC<staffUpdateProps> = ({IsOpen,CloseModal, StaffName, formData, setFormData, selectStaffId, currentPage}) => {
  
  let [highlightfirstname, setHighlight] = useState(false);
  let [highlightlastname, sethighlightlastname] = useState(false);
  let [highlightssn, sethighlightssn] = useState(false);
  let [highlightemail, sethighlightemail] = useState(false);
  let [highlightzipcode, sethighlightzipcode] = useState(false);
  let [highlightdate, sethighlightdate] = useState(false);
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());
  const { genderList, specialityList, rolesList } = useSelector((state: any) => state.Staff);
  const { organization } = useSelector((state: any) => state.Login);
  const dispatch = useDispatch<any>()
   const highlight = () => {
    let highlighted = false;
    if (!formData.firstName.trim()) {
      setHighlight(true);
      toast.error("Please Enter First Name");
      highlighted = true;
    } else {
      setHighlight(false);
    }

    if (!formData.lastName.trim()) {
      sethighlightlastname(true);
      toast.error("Please Enter LastName");
      highlighted = true;
    } else {
      sethighlightlastname(false);
    }

    if (!formData.dateofBirth.trim()) {
      sethighlightdate(true);
      toast.error("Please Enter birthDate");
      highlighted = true;
    } else {
      sethighlightdate(false);
    }

    if (!formData.ssn.trim()) {
      sethighlightssn(true);
      toast.error("Please Enter ssn");
      highlighted = true;
    } else {
      sethighlightssn(false);
    }

    if (!formData.email.trim()) {
      sethighlightemail(true);
      toast.error("Please Enter email");
      highlighted = true;
    } else {
      sethighlightemail(false);
    }

    if (!formData.zip.trim()) {
      sethighlightzipcode(true);
      toast.error("Please Enter Postalcode");
      highlighted = true;
    } else {
      sethighlightzipcode(false);
    }
    return highlighted;
  };
  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstname') {
      formData.firstName = event.target.value;
      setHighlight(false);
    } else if (event.target.id === 'middlename') {
      formData.middleName = event.target.value;
    } else if (event.target.id === 'lastname') {
      formData.lastName = event.target.value;
      sethighlightlastname(false);
    } else if (event.target.id === 'dateofBirth') {
      formData.dateofBirth = event.target.value;
      sethighlightdate(false);
    } else if (event.target.id === 'email') {
      formData.email = event.target.value;
      sethighlightemail(false);
    } else if (event.target.id === 'ssn') {
      formData.ssn = event.target.value;
      sethighlightssn(false);
    } else if (event.target.id === 'addressLine1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'addressline2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'mobilePhone') {
      formData.mobilePhone = event.target.value;
    } else if (event.target.id === 'npi') {
      formData.npi = event.target.value;
    } else if (event.target.id === 'speciality') {
      formData.speciality = event.target.value;
    } else if (event.target.id === 'roles') {
      formData.roles = event.target.value;
    } else if (event.target.id === 'userType') {
      formData.userType = event.target.value;
    }
    setFormData({ ...formData });
  }
  const handleSelectChange = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value });
  }; 

  const handleAddress = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [loading1, setLoading] = useState(false);
  const handlePostalCodeChange = async (e: any) => {
    formData.zip = e.target.value;
    setFormData({ ...formData });
    sethighlightzipcode(false);
    if (e.target.value.length === 5) {
      setLoading(true);
      try {
        const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
        formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
        formData.country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
        formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
        setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]].map((k: any) => k.city_en) : [])
      } catch (error) {
        console.error('Error fetching city:', error);
      } finally {
        setLoading(false);
      }
    } else {
      formData.state = "";
      formData.country = "";
      formData.city = "";
      setLoading(false);
    }
    setFormData({ ...formData });
  };

  const handleSaveChanges = () => {
    const isHighlighted = highlight();
    if (!isHighlighted) {
      if (!selectStaffId) {
        return;
      } else if (formData.zip.length > 0 && formData.city === "" && formData.state === "") {
        formData.city = "";
        formData.state = "";
        formData.country = "";
        toast.error("Please Enter Valid Zip Code");
        return;
      }
      const updatedStaffFields = {
        id: selectStaffId,
        name: [
          {
            use: formData.middleName,
            given: formData.firstName,
            family: formData.lastName,
          },
        ],
        gender: formData.gender,
        email: formData.email,
        role: formData.roles,
        organization,
        startDate: formData.startDate,
        speciality: [formData.speciality],
        dateofBirth: formData.dateofBirth,
        ssn: formData.ssn,
        npi: formData.npi,
        userType: formData.userType,
        contact: [
          {
            address: [
              {
                addressLine1: formData.addressLine1,
                addressLine2: formData.addressLine2,
                city: formData.city,
                state: formData.state,
                country: formData.country,
                zip: formData.zip,
              },
            ],
            mobilePhone: formData.mobilePhone,
          },
        ],
        active: formData.active,
        profile: formData.profile
      };
      dispatch(
        updateStaffDetails(
          selectStaffId,
          updatedStaffFields,
          organization,
          CloseModal,
          currentPage
        )
      );
      CloseModal(false);
    };
  };

  const hiddenFileInput = useRef<any>(null);

  const handleProfileChange = () => {
    if (hiddenFileInput !== null) {
      hiddenFileInput.current.click();
    }
  }
  const handleImageChange = (e: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      formData.profile = reader.result !== null ? reader.result.toString() : ""
      setFormData({ ...formData });
    }
    reader.readAsDataURL(e.target.files[0]);
  }
  const [profilePath, setProfilePath] = useState<any>(uploadImage)
  useEffect(() => {
    if (!formData) return 
    console.log(formData.profile)
      setProfilePath(formData.profile && formData.profile.length >0 ? formData.profile : uploadImage )
  }, [formData]) 
  return (
    <div>
      <Modal
        isOpen={IsOpen}
        // toggle={() => setEditModal(false)}
        centered
        size="lg"
      >
        <div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row">
            <div className="container col-md-12">
              <ModalHeader toggle={() => CloseModal(false)}>
                {StaffName}<span>
                <div onClick={handleProfileChange} hidden={formData.profile !== null && formData.profile !== "" && formData.profile !== "string"} style={{ left: "unset", width: "156px" }} className="vector-group">
                  <img className="frame-child4" style={{ cursor: 'pointer' }} alt="" src={profilePath} />
                  <div className="upload-profile-pic1" style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>UPLOAD PROFILE PIC</div>
                </div>
                <div hidden={formData.profile === null || formData.profile === "" || formData.profile === "string"} style={{ left: "unset", width: "182px" }} className="vector-group">
                  <div style={{ position: 'absolute', top: "2px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} alt='' src={formData.profile} onClick={handleProfileChange} /><input className="vector-group" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
                </div>
              </span>
              </ModalHeader>
              <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                <div className="row w-100 ">
                  <div className="col-md-4">
                    <TextField
                      error={highlightfirstname}
                      id="firstname"
                      name="firstName"
                      label="First Name"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="middlename"
                      name="middleName"
                      label="Middle Name"
                      placeholder="Enter Middle Name"
                      value={formData.middleName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      error={highlightlastname}
                      id="lastname"
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter LastName"
                      value={formData.lastName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    <Autocomplete
                      id='gender'
                      options={genderList?.map((item: any) => item.value)}
                      value={formData.gender}
                      onChange={(e, v) => handleSelectChange('gender', v)}
                      renderInput={(params) => (
                        <TextField {...params} label='Gender' variant="outlined" />
                      )}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      error={highlightdate}
                      id="dateofBirth"
                      name="dateofBirth"
                      label="Date Of Birth"
                      placeholder="Enter DateOfBirth"
                      value={formData.dateofBirth}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      error={highlightssn}
                      id="ssn"
                      name="ssn"
                      label="SSN"
                      placeholder="Enter SSN"
                      value={formData.ssn}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      inputProps={{
                        maxLength: 9,
                        pattern: '[0-9]*',
                        inputMode: 'numeric'
                      }}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <TextField
                      error={highlightemail}
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      id="mobilePhone"
                      name="mobilePhone"
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                      value={formData.mobilePhone}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <TextField
                      id="npi"
                      name="npi"
                      label="NPI#"
                      placeholder="Enter NPI"
                      value={formData.npi}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                    <div className='col-md-6 mt-3 '>
                  <FormControl fullWidth>
                    <InputLabel >Role</InputLabel>
                    <Select
                      value={formData.userType}
                      label="Role"
                      onChange={(event) =>  setFormData({
                        ...formData,
                        userType: event.target.value,
                      })}
                    >
                      <MenuItem value={'Admin'}>Admin</MenuItem>
                      <MenuItem value={'Staff'}>Staff</MenuItem>
                      <MenuItem value={'Receptionist'}>Receptionist</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                </div>
                <div className="row w-100 mt-2">
                  <div className="col-md-6">
                    <Autocomplete
                      id='speciality'
                      options={specialityList?.map((item: any) => item.value)}
                      value={formData.speciality}
                      onChange={(e, v) => handleSelectChange('speciality', v)}
                      renderInput={(params) => (
                        <TextField {...params} label='Speciality' variant="outlined" />
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Autocomplete
                      id='roles'
                      options={rolesList?.map((item: any) => item.value)}
                      value={formData.roles}
                      onChange={(e, v) => handleSelectChange('roles', v)}
                      renderInput={(params) => (
                        <TextField {...params} label='Job Title' variant="outlined" />
                      )}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="addressLine1"
                      name="addressLine1"
                      label="Address Line 1"
                      placeholder="Enter Address"
                      value={formData.addressLine1}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="addressLine2"
                      name="addressLine2"
                      label="Address Line 2"
                      placeholder="Enter Address"
                      value={formData.addressLine2}
                      onChange={handleAddress}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mt-3 mb-2">
                    <TextField
                      error={highlightzipcode}
                      id="zipcode"
                      label="Zip/Postal Code"
                      variant="outlined"
                      fullWidth
                      value={formData.zip}
                      onChange={handlePostalCodeChange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      inputProps={{
                        maxLength: 5,
                        pattern: "\\d{5}",
                      }}
                    />
                    {loading1 && <CircularProgress size={'25px'} />}
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4">
                    {cityDropDown !== null && cityDropDown.length === 1 ?
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={formData.city}
                        onChange={handleinputchange}
                        InputLabelProps={{
                          shrink:true,
                      }}
                      /> :
                      <Autocomplete
                        id="city"
                        options={cityDropDown}
                        value={formData.city}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                        sx={{ width: "100%" }}
                        size="medium"
                        renderInput={params =>
                          <TextField
                            name=""
                            {...params}
                            variant="outlined"
                            label="City"
                            placeholder=""
                            margin="none"
                            size="medium"
                            fullWidth
                          />
                        }
                      />}
                  </div>
                  <div className="col-md-4 dropup">
                    <TextField
                      id="state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={formData.state}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="country"
                      label="Country"
                      variant="outlined"
                      fullWidth
                      value={formData.country}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                    />
                  </div>
                </div>
              </ModalBody>
            </div>
          </div>
        </div>
        <ModalFooter className="">
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() =>CloseModal(false)}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveChanges}></Button>
          </div>
        </ModalFooter>
      </Modal>      
    </div>
  )
}

export default StaffUpdate