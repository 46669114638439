import axios from 'axios'
import { isLoading, setErrorMessage, setIsLoadingFalse, getBeaconSuccess, getTotalElementsSuccess, getGateWaySuccess, getGateWayTotalElementsSccess, getSirenTotalElementsSuccess, getSirenSuccess } from "./reducer"
import { toast } from 'react-toastify';
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const getAllBeacon = async (dispatch: any, organization: string, page: number, search: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const res = await axios.get(
      `${baseURL}/sensor/getAllByorgId/${organization}?page=${page}&search=${search}` ,{headers: header1}
    );
    if(res.data.message.code === successCode){
      dispatch(setIsLoadingFalse());
      dispatch(getBeaconSuccess(res.data.data.content))
      dispatch(getTotalElementsSuccess(res.data.data.totalElements))
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.warn(error);
  }
};

  export const updatedSensorDetails = async (dispatch: any, id: string, data: any,org:string) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
      const response = await axios.put(`${baseURL}/sensor/updateSensorTableByDeviceName/${id}`, data ,{headers: header1});
      if (response.data.message.code === successCode) {
        dispatch(setIsLoadingFalse());
        toast.success(response.data.message.description)
        getAllBeacon(dispatch,org,0, '');
      } else {
      dispatch(setIsLoadingFalse());
        dispatch(setErrorMessage(response.data.message.description));
        toast.error(response.data.message.description)
      }
    } catch (error) {
      dispatch(setIsLoadingFalse());
      toast.error("Error: something went wrong.")
    }
  };
  export const getAllSirenByOrg = async (dispatch:any, organization: string, page: number, search: any) => {
    const { header1 } = authorize();
    try {
      const res = await axios.get(`${baseURL}/siren/getSirenByOrgIdWithSearch?organization=${organization}&page=${page}&search=${search}` ,{headers: header1})
      if (res.data.message.code === successCode) {
        dispatch(getSirenSuccess(res.data.data.content))
        dispatch(getSirenTotalElementsSuccess(res.data.data.totalElements))
      }
    } catch (error:any) {
      console.warn("API Error :",error)
    }
  }

  export const getAllGateWayByOrg = async (dispatch:any, organization: string, page: number, search: any) => {

    const { header1 } = authorize();
    try {
      const res = await axios.get(`${baseURL}/gateway/getGatewayByOrgIdWithSearch?organization=${organization}&page=${page}&search=${search}`, {headers: header1})
      if (res.data.message.code === successCode) {
        dispatch(getGateWaySuccess(res.data.data.content))
        dispatch(getGateWayTotalElementsSccess(res.data.data.totalElements))
      }
    } catch (error: any) {
      console.warn("API Error: ", error)
    }
  }
