import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import pageError from './../../assets/images/404Page.png'

const ErrorPage = () => {
    const jwt = sessionStorage.getItem('jwt')
    const navigate = useNavigate()
    useEffect(()=>{
        if (!jwt) {
            navigate('/')
        }
    },[])
  return (
    <div  className={` d-flex justify-content-center  `} >

      <img className='w-75 h-75' src={pageError}/>
      {/* <div className='d-flex justify-content-center mt-5 '>
      <div className='row'>
       <h6 className='fs-3 d-flex justify-content-center text-decoration-underline '>WE CAN'T FOUND THIS PAGE</h6>
       <h1 style={{color: 'red', fontSize: '80px'}} className=' d-flex justify-content-center '>404</h1>
       <h6 className='fs-3 d-flex justify-content-center '>PAGE LOST</h6>
      </div>
       
      </div> */}
    </div>
  )
}

export default ErrorPage