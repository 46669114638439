import React, { useEffect, useRef, useState } from "react";
import LoginImage from "../../assets/images/login.png";
import MHCLogo from "../../assets/images/mettlerTitle.png"
import { Link, useNavigate } from "react-router-dom";
import { getOrganization, handleLogin } from "../../slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AccountCircle, Visibility, VisibilityOff, Lock } from "@mui/icons-material";
import Loader from "../../components/loader/Loader";
import nameimg from "../../../src/assets/images/Vector.png";
import keyimg from "../../../src/assets/images/Vector (1).png";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const usernameInputRef = useRef<HTMLInputElement | null>(null);
  const [isChecked, setChecked] = useState<boolean>(false);
  const { loading } = useSelector(
    (state: any) => state.Login
  );
  useEffect(() => {
    getOrganization(dispatch);
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, [dispatch]);

  const getDeviceIdentifier = () => {
    fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
            const ipAddress = data.ip;
        })
        .catch(error => {
            console.error('Error fetching IP address:', error);
        });
};

  const triggerLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    const deviceIdentifier = await getDeviceIdentifier(); 
    e.preventDefault();
    if (username === '' || password === '') toast.error("Error: Please enter your username and password.")
    const body = {
      username,
      password,
      deviceIdentifier: deviceIdentifier
    };
    handleLogin(dispatch, body, navigate, isChecked);
  };
  useEffect(() => {
    if (!sessionStorage.getItem('jwt')) {
      // localStorage.clear()
      // if () return
      sessionStorage.removeItem('authStaff');
      return
    }
    navigate('/secret-key')
  }, [])
  useEffect(() => {
    if(localStorage.getItem('userName') && localStorage.getItem('password')) {
      setChecked(true)
      setUsername(localStorage.getItem('userName') || '')
      setPassword(localStorage.getItem('password') || '')
    }
    return () => {
      setUsername('')
      setPassword('')
    }
  }, [])
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleForget = () => {
    navigate('/forgot-password')
  }

  const handlecheck = () => {
    setChecked(!isChecked)
    
  }
  return (
    <div className="row h-100 w-100">
      {loading && <Loader />}
      <div className="col-md-7 position-relative p-0">
        <div >
          <p className="position-absolute" style={{ color: '#fff', fontWeight: 'bold', fontSize: '30px', marginLeft: '100px', marginTop: '360px' }}>Welcome to <br />Q15 Safety  check App</p>
          <p className="position-absolute" style={{ color: '#fff', fontSize: '15px', marginLeft: '100px', marginTop: '470px' }}>we specialize in developing cutting-edge <br /> software solutions for the healthcare industry.</p>
          <img className="position-absolute" style={{ height: '25px', width: '200px', marginLeft: '70px', marginTop: '80px' }} src={MHCLogo} alt="MHC Logo" />
          <p className="position-absolute" style={{ color: '#fff', fontWeight: 'bold', fontSize: '20px', marginLeft: '125px', marginTop: '120px' }}>Q15-Safety App</p>
          <img
            src={LoginImage}
            alt="LogIn Image"
            className="img-fluid"
            style={{ objectFit: "cover", height: '100vh' }}
          />
        </div>
      </div>
      <div className="col-md-5 d-flex flex-column align-items-center justify-content-md-center">
        <form className="rounded col-md-9" onSubmit={triggerLogin}>

          <div className="d-flex flex-column">
            <div>
              <p style={{ fontSize: '28px', color: '#415076', fontWeight: 600 }}>Hello!</p>
            </div>
            <div style={{ position: "relative", top: "-16px" }}>
              <p style={{ fontSize: '18px', color: '#415076' }}>Login into your account</p>
            </div>
            <div >

            </div>
            <div className="d-flex flex-column gap-3">
              <TextField
                id="outlined-basic-1"
                label={<><span><img src={nameimg}></img></span>&nbsp;&nbsp;&nbsp;<span>Username</span></>}
                variant="outlined"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputLabelProps={{
                  shrink:true,
                }}
                inputRef={(input) => {
                  usernameInputRef.current = input;
                }}
              />
              <TextField
                id="outlined-basic-2"
                label={<><span><img src={keyimg}></img></span>&nbsp;&nbsp;&nbsp;<span>Password</span></>}
                variant="outlined"
                fullWidth
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{
                  shrink:true,
              }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handlePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="d-flex mt-2 row">
            <div className="col-md-1"></div>
            <div className="form-check col-md-4 p-0" style={{ textAlign: 'start' }}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handlecheck}
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" style={{ fontSize: '14px' }} htmlFor="exampleCheck1">
                Remember Me
              </label>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <p className="text-end" style={{ color: '#2D70F4', cursor: 'pointer', fontSize: '14px' }} onClick={handleForget}>Forgot Password?</p>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary col-md-12"
            style={{ height: "56px" }}
          >
            Login
          </button>
        </form>
        <div className="mt-3">
          <p style={{fontSize:'10px'}}>© 2024 Mettler Health Tech Inc.,</p>
        </div>
      </div>
    </div>
  );
};

export default Login;