import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import './staff.css'
import { DatePicker } from '@mui/x-date-pickers';
import { formatDateToYYYYMMDD, formatPhoneNumber, formatSSN } from '../../helpers/common';
import { toast } from 'react-toastify';
import { createStaff } from '../../slices/thunk';
import Loader from '../../components/loader/Loader';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from 'primereact/button';
import * as Constants from "../Constants/Constant";
import { CircularProgress } from '@material-ui/core';
import uploadImage from "../../assets/images/rectangle-59991.svg";

interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

interface DropdownResponse {
  message: {
    code: string;
    description: string;
  };
  data: Dropdown[];
}
interface StaffCreationFormProps {
  modal: boolean;
  toggle: () => void;
  currentPage: number;
}
const StaffCreationForm: React.FC<StaffCreationFormProps> = ({ modal, toggle, currentPage }) => {
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());
  const [userType, setUserType] = React.useState('');
  let [highlightfirstname, setHighlight] = useState(false);
  let [highlightlastname, sethighlightlastname] = useState(false);
  let [highlightssn, sethighlightssn] = useState(false);
  let [highlightemail, sethighlightemail] = useState(false);
  let [highlightzipcode, sethighlightzipcode] = useState(false);
  let [highlightdate, sethighlightdate] = useState(false);
  const { organization } = useSelector(
    (state: any) => state.Login
  );
  const { loading, genderList, specialityList, rolesList } = useSelector(
    (state: any) => state.Staff
  );
  const dispatch = useDispatch<any>()
  const initdrop = {
    gender: null,
    Country: '',
    roles: null,
    state: '',
    speciality: null,
  }
  const [selectedValues, setSelectedValues] = useState<any>(initdrop);
  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
  };

  const initFormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    dateofBirth: '',
    ssn: '',
    npi: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
    startDate: '',
    userType: '',
    country: '',
    gender: '',
    speciality: '',
    roles: '',
    profile: ''
  }
  let [formData, setFormData] = useState(initFormData);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})

  const [disabled, setDisabled] = useState<boolean>(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(e.target.checked);
  };
  const [loading1, setLoading] = useState(false);
  const handlePostalCodeChange = async (e: any) => {
    const newZip = e.target.value;
    formData.postalCode = newZip;
    setFormData({ ...formData });
    sethighlightzipcode(false);
    if (e.target.value.length === 5) {
      setLoading(true);
      try {
        const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
        formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
        formData.country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
        formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
        setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]].map((k: any) => k.city_en) : [])
      } catch (error) {
        console.error('Error fetching city:', error);
      } finally {
        setLoading(false);
      }
    } else {
      formData.state = "";
      formData.country = "";
      formData.city = "";
      setLoading(false);
    }
    setFormData({ ...formData });
  };

  const handleDateChange = (date: any) => {
    setFormData({ ...formData, dateofBirth: formatDateToYYYYMMDD(date) })
    if (!date) {
      sethighlightdate(true);
    } else {
      sethighlightdate(false);
    }
  };
  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstname') {
      formData.firstName = event.target.value;
      setHighlight(false);
    } else if (event.target.id === 'middlename') {
      formData.middleName = event.target.value;
    } else if (event.target.id === 'lastname') {
      formData.lastName = event.target.value;
      sethighlightlastname(false);
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'Email') {
      formData.email = event.target.value;
      sethighlightemail(false);
    } else if (event.target.id === 'SSN') {
      formData.ssn = event.target.value;
      sethighlightssn(false);
    } else if (event.target.id === 'addressline1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'addressline2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'PhoneNumber') {
      formData.phoneNumber = event.target.value;
    } else if (event.target.id === 'npi') {
      formData.npi = event.target.value;
    }
    setFormData({ ...formData });
  }
  const handleSaveClick = async (save: boolean) => {
    let newErrors = {
      firstName: !formData.firstName,
      middleName: !formData.middleName,
      lastName: !formData.lastName,
      dateofBirth: !formData.dateofBirth,
      ssn: !formData.ssn,
      npi: !formData.npi,
      addressLine1: !formData.addressLine1,
      addressLine2: !formData.addressLine2,
      city: !formData.city,
      state: !formData.state,
      postalCode: !formData.postalCode,
      phoneNumber: !formData.phoneNumber,
      email: !formData.email,
      country: !formData.country,
      speciality: !formData.speciality,
      roles: !formData.roles,
      usertype: !userType
    }
    // highlight();
    setNameError(newErrors)
    const hasErrors = Object.values(newErrors).some(error => error);
    if (hasErrors) {
      toast.error("Please Fill Required Field")
      return;
    }
    if (formData.postalCode.length > 0 && formData.city === "" && formData.state === "") {
      formData.city = "";
      formData.state = "";
      formData.country = "";
      toast.error("Please Enter Valid Zip Code");
      return;
    }

    const requestBody = {
      id: '',
      name: [
        {
          use: formData.middleName,
          given: formData.firstName,
          family: formData.lastName,
        },
      ],
      gender: !disabled ? formData?.gender : '',
      email: formData.email,
      role:formData?.roles || '',
      organization,
      userType: userType,
      startDate: formData.startDate,
      speciality: [
        formData?.speciality || ''
      ],
      dateofBirth: formData.dateofBirth,
      ssn: (formData.ssn).slice(0,9),
      npi: formData.npi,
      contact: [
        {
          address: [{
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            city: formData.city,
            country: formData.country || '',
            state: formData.state || '',
            zip: formData.postalCode
          }],
          mobilePhone: formData.phoneNumber
        }
      ]
    };

    dispatch(createStaff(requestBody, save ? null : handleCancel, organization, currentPage))

  };

  const handleCancel = () => {
    setFormData(initFormData);
    setSelectedValues(initdrop);
    setDisabled(false)
    toggle();
    setUserType('');
    setNameError({})
  }
  const handleSelectChange = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const hiddenFileInput = useRef<any>(null)
  const handleProfileChange = () => {
    if (hiddenFileInput !== null) {
      hiddenFileInput.current.click();
    }
  } 
  const handleImageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result !== null ? reader.result.toString() : "";
          setFormData(prevValues => ({ ...prevValues, profile: result }));
        }
        reader.readAsDataURL(file);
      }
    } else {
      console.log("No file selected");
    }
  }

  return (
    <Modal isOpen={modal} 
    // toggle={handleCancel} 
    centered size='lg'>
      <div className="d-flex align-items-center justify-content-center">
        {loading && <Loader />}
        <div className="row">
          <div className="container col-md-12">
            <ModalHeader toggle={handleCancel}>
              Staff Register
                <span>
                <div onClick={handleProfileChange} hidden={formData.profile !== null && formData.profile !== "" && formData.profile !== "string"} style={{ left: "unset", width: "156px" }} className="vector-group">
                  <img className="frame-child4" style={{ cursor: 'pointer' }} alt="" src={uploadImage} />
                  <div className="upload-profile-pic1" style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>UPLOAD PROFILE PIC</div>
                </div>
                <div hidden={formData.profile === null || formData.profile === "" || formData.profile === "string"} style={{ left: "unset", width: "182px" }} className="vector-group">
                  <div style={{ position: 'absolute', top: "2px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} alt='' src={formData.profile} onClick={handleProfileChange} /><input className="vector-group" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
                </div>
              </span>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
              <div className="row w-100 " style={{ marginTop: '10px' }}>
                <div className='col-md-4 mb-2'>
                  <TextField
                   id="firstname"
                   label="First Name" 
                   variant="outlined" 
                   value={formData.firstName} 
                   fullWidth
                   onChange={(e)=>{
                     handleinputchange(e)
                     setNameError({...nameError, firstName: false})
                   }}
                   error={!!nameError.firstName}
                   helperText={nameError.firstName ? 'First Name is required' : ''}
                   required
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField id="middlename" label="Middle Name" variant="outlined" value={formData.middleName} fullWidth onChange={handleinputchange} />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                   id="lastname" 
                   label="Last Name" 
                   variant="outlined" 
                   value={formData.lastName} 
                   fullWidth
                   onChange={(e)=>{
                     handleinputchange(e)
                     setNameError({...nameError, lastName: false})
                   }}
                   error={!!nameError.lastName}
                   helperText={nameError.lastName ? 'Last Name is required' : ''}
                   required
                  />
                </div>
              </div>
              <div className="row w-100">
              <div className='col-md-4'>
                <Autocomplete
                  id='gender'
                  options={genderList?.map((item: any) => item.value)}
                  value={formData.gender}
                  onChange={(e, v) => handleSelectChange('gender', v)}
                  renderInput={(params) => (
                    <TextField {...params} label='Gender' variant="outlined" />
                  )}
                  disabled={disabled}
                />
                <div style={{ display: 'flex', marginLeft:'15px', alignItems: 'center', marginTop: '-9px' }}>
                  <FormControlLabel
                    control={<Checkbox checked={disabled} onChange={handleCheckboxChange} />}
                    label="Declined to specify"
                  />
                </div>
              </div>

                <div className='col-md-4'>
                  <DatePicker
                    sx={{
                      border: highlightdate ? "1px solid red" : "",
                      borderRadius: "5px"
                    }}
                    label={'Date Of Birth'}
                    format='DD-MM-YYYY'
                    onChange={handleDateChange}
                  />
                </div>
                <div className='col-md-4'>
                <TextField 
                 id="SSN" 
                 label="SSN" 
                 variant="outlined" 
                 value={formatSSN(formData.ssn)} 
                 fullWidth
                 onChange={(e)=>{
                   handleinputchange(e)
                   setNameError({...nameError, ssn: false})
                 }}
                 error={!!nameError.ssn}
                 helperText={nameError.ssn ? 'SSN is required' : ''}
                 required
                />
                </div>
              </div>
              <div className="row w-100 mt-2">
                <div className='col-md-6 mb-2'>
                  <TextField 
                    id="Email" 
                    label="Email" 
                    variant="outlined" 
                    value={formData.email} 
                    fullWidth
                    onChange={(e)=>{
                      handleinputchange(e)
                      setNameError({...nameError, email: false})
                    }}
                    error={!!nameError.email}
                    helperText={nameError.email ? 'Email is required' : ''}
                    required
                  />
                </div>
                <div className='col-md-6 mb-2'>
                <TextField id="PhoneNumber" label="Phone Number" value={formatPhoneNumber(formData.phoneNumber)} variant="outlined" fullWidth onChange={handleinputchange} />
                </div>
              </div>
              <div className="row w-100 ">
              <div className='col-md-6 mb-2'>
                  <TextField id="npi" label="NPI#" value={formData.npi} variant="outlined" fullWidth onChange={handleinputchange} />
                </div>
                <div className='col-md-6 mb-2'>
                  <FormControl fullWidth required error={!!nameError.roles} >
                    <InputLabel >Role</InputLabel>
                    <Select
                      value={userType}
                      label="Role"
                      onChange={(e)=>{
                        handleChange(e)
                        setNameError({...nameError, userType: false})
                      }}                      
                    >
                      <MenuItem value={'Admin'}>Admin</MenuItem>
                      <MenuItem value={'Staff'}>Staff</MenuItem>
                      <MenuItem value={'Receptionist'}>Receptionist</MenuItem>
                    </Select>
                    {nameError.usertype && <p style={{ color: 'red', fontSize:'12px' }}>Role is required</p>}
                  </FormControl>
                </div>
                
              </div>
              <div className='row w-100'>
                <div className='col-md-6 mb-2'>
                  <Autocomplete
                    id='speciality'
                    options={specialityList?.map((item: any) => item.value)}
                    value={formData.speciality}
                    onChange={(e, v) => handleSelectChange('speciality', v)}
                    renderInput={(params) => (
                      <TextField {...params} label='Speciality' variant="outlined" />
                    )}
                  />
                </div>
                <div className='col-md-6 mb-2'>
                  <Autocomplete
                    id='roles'
                    options={rolesList?.map((item: any) => item.value)}
                    value={formData.roles}
                    onChange={(e, v) => handleSelectChange('roles', v)}
                    renderInput={(params) => (
                      <TextField {...params} label='Job Title' variant="outlined" />
                    )}
                  />
                </div>
              </div>
              <div className="row w-100 ">
                <div className='col-md-4 mb-2'>
                  <TextField id="addressline1" label="Address Line 1" value={formData.addressLine1} variant="outlined" fullWidth onChange={handleinputchange} />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField id="addressline2" label="Address Line 2" value={formData.addressLine2} variant="outlined" fullWidth onChange={handleinputchange} />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="zipcode"
                    label="Zip/Postal Code"
                    variant="outlined"
                    fullWidth
                    value={formData.postalCode}
                    onChange={(e)=>{
                      handlePostalCodeChange(e)
                      setNameError({...nameError, postalCode: false})
                    }}
                    error={!!nameError.postalCode}
                    helperText={nameError.postalCode ? 'Zip/Postal Code is required' : ''}
                    required
                    inputProps={{
                      maxLength: 5,
                      pattern: "\\d{5}",
                    }}
                  />
                  {loading1 && <CircularProgress size={'25px'} />}
                </div>
              </div>
              <div className="row w-100 ">
              <div className='col-md-4 mb-2'>
                  {cityDropDown !== null && cityDropDown.length === 1 ?
                    <TextField
                      id="city"
                      label="City"
                      variant="outlined"
                      fullWidth
                      value={formData.city}
                      onChange={handleinputchange}
                    /> :
                    <Autocomplete
                      id="city"
                      options={cityDropDown}
                      value={formData.city}
                      getOptionLabel={(option) => option}
                      onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                      sx={{ width: "100%" }}
                      size="medium"
                      renderInput={params =>
                        <TextField
                          name=""
                          {...params}
                          variant="outlined"
                          label="City"
                          placeholder=""
                          margin="none"
                          size="medium"
                          fullWidth
                        />
                      }
                    />}
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="state"
                    label="State"
                    variant="outlined"
                    fullWidth
                    value={formData.state}
                    onChange={handleinputchange}
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="country"
                    label="Country"
                    variant="outlined"
                    fullWidth
                    value={formData.country}
                    onChange={handleinputchange}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="">
              <div className="d-flex gap-3 justify-content-center">
                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleCancel}></Button>
                <Button label="Save" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSaveClick(true)}></Button>
                <Button label="Save & Exit" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSaveClick(false)}></Button>
              </div>
            </ModalFooter>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StaffCreationForm;