import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import "react-dropdown-tree-select/dist/styles.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Image2 from '../../assets/images/unblock3.png'
import { useDispatch, useSelector } from "react-redux";
import { handleUnblock }  from "../../slices/secretkey/thunk";
import { useNavigate } from "react-router-dom";

const OTPInput = ({ value, onChange, onSubmit }: any) => {
  const [show,setShow]=useState<boolean[]>([true,true,true,true,true,true]);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = e.target.value.replace(/\D/g, '').substring(0, 1);
    onChange(newValue, index);
  
    if (newValue !== '') {
      // Move focus to the next input field if not the last one
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
    if (value !== "") {
      setShow(prevShowNumbers => {
        const newShowNumbers = [...prevShowNumbers];
        newShowNumbers[index] = false;
        return newShowNumbers;
      });
    
      setTimeout(() => {
        setShow(prevShowNumbers => {
          const resetShowNumbers = [...prevShowNumbers];
          resetShowNumbers[index] = true;
          return resetShowNumbers;
        });
      }, 500);
    }
  };

  
  const handleKeyDown = (e: any, index: number) => {
    if (e.key === 'Enter') {
      onSubmit();
      inputRefs.current[0]?.focus();
    }
    if (e.key === 'Backspace') {
      const newValue = e.target.value.replace(/\D/g, '').substring(0, 1);
      if (newValue !== '') {
        onChange('', index);
        inputRefs.current[index]?.focus();                
      } else {
        onChange('', index);
        inputRefs.current[index - 1]?.focus();
      }
    }
  };
  
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, value.length).map((ref, index) => ref || null);
  }, [value]);
  return (
    <div className="otp-input-container mb-1">
      {[...Array(6)].map((_, index) => (
        <input
          key={index}
          type={show[index] ? 'password':'text'}
          maxLength={1}
          className="otp-input"
          value={value[index] || ""}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          style={{
            width: '40px',
            height: '40px',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #0f3995',
            textAlign: 'center',
            borderRadius: '8px',
            marginRight:'15px',
            left:'7px'
          }}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
    </div>
  );
};

const Unblock = () => {
  const { jwt, userType, organization, userDetails, userData } = useSelector((state: any) => state.Login);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [otp, setOTP] = useState(Array(6).fill(""));
  const [secretKey, setSecretKey] = useState('');

  const handleVerify = () => {
    const secretKey = otp.join("");
    const body ={
      secretKey,
      jwt
    }  
  handleUnblock(secretKey, userDetails, navigate, userType, organization, dispatch, setSecretKey);  
    setTimeout(() => {
      setOTP(Array(6).fill(""));
    }, 1000);
  };

  const handleChangeOTP = (newValue: any, index: any) => {
    const newOTP = [...otp];
    newOTP[index] = newValue;
    setOTP(newOTP);
  };

  useEffect(() => {
    // Clear OTP after a timeout
    const timeoutId = setTimeout(() => {
      setOTP(Array(6).fill(""));
    }, 10000); // Reset OTP after 10 seconds
    return () => clearTimeout(timeoutId);
  }, [otp]);

  return (
    <div className="row d-flex h-100 w-100">
      <div className="col-md-6">
        <img className='img-fluid' style={{height:'99.5vh',width:'100%'}} src={Image2} alt="Secret"></img>
      </div>
      <div className="col-md-6 d-flex flex-column justify-content-center align-items-center ">
        <div>
          <div className="">
            Your account is temporarily blocked. To unblock your account
          </div>
          <span style={{ display: 'block',marginBottom:'15px'}} className="passCodeText">
            Enter your Passcode :
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <OTPInput value={otp} onChange={handleChangeOTP} onSubmit={handleVerify} />
        </div>
        <div className="buttonPasscode mt-4">
          <Button
            onClick={handleVerify}
            style={{
              width: '321px',
              position: 'relative',
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              fontSize: '13px',
              height: '48px',
              backgroundColor: '#1F489F',
              color: '#fff',
            }}
            label="Submit"
          ></Button>
        </div>
        <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => navigate('/resetSecretKey')}>
          <div className="forgotPassCode p-3">Forgot Passcode?</div>
        </a>
      </div>
    </div>
  );
};

export default Unblock;