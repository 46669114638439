import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { publicRoutes, AdminRoutes, SuperAdminRoutes,StaffRoutes, SystemAdminRoutes, ReceptionistRoutes } from './Routes/allRoutes';
import { ToastContainer } from 'react-toastify';
import ProtectedAuth from './Routes/protectedAuth';
import Sidebar from './components/sidebar/sidebar';
import { useSelector } from 'react-redux';

const App = () => {
  
  const jwt = useSelector((state: any) => state.Login.jwt);
  const userType = localStorage.getItem('userType')
  const getAuthenticatedRoutes = () => {
    if (userType === 'Super Admin') {
      return SuperAdminRoutes;
    } else if (userType === 'Admin') {
      return AdminRoutes;
    } else if (userType === 'System Admin') {
      return SystemAdminRoutes;
    } else if (userType === 'Staff') {
      return StaffRoutes;
    } else if (userType === 'Receptionist') {
      return ReceptionistRoutes;
     } else {
      return [];
    }
  };
  const location = useLocation();
  const authProtectedRoutes = getAuthenticatedRoutes();
  const shouldRenderSidebar = jwt && location.pathname !== '/secret-key';
  const patient = location.pathname === '/patient-view';
  const staffView = location.pathname === '/staff-view';
  const patientView = patient || staffView
  const q15reports = location.pathname === '/q15-report';
   
 
   

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} key={idx} element={route.component} />
        ))}
      </Routes>
      {shouldRenderSidebar && (
        <Sidebar>
          { !patientView && 
          <div className='container' style={{ padding:'63px 10px 0px', maxHeight: '700px' }}>
          </div> }
          <Routes>
            {authProtectedRoutes.map((route:any, idx:any) => (
              <Route
                path={route.path}
                key={idx}
                element={
                  <React.Fragment>
                    <ProtectedAuth>{route.component}</ProtectedAuth>
                  </React.Fragment>
                }
              />
            ))}
          </Routes>
        </Sidebar>
      )}
      <ToastContainer theme='dark' autoClose={1000}/>
    </React.Fragment>
  );
};

export default App;
