import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    devices: [],
    orgName: '',
    patientData: [],
    orgTimeZone: '',
    currentDateTime: ''
}

const LargeScreenSlice = createSlice({
    name: 'LargeScreen',
    initialState,
    reducers : {
      setDevices (state, action) {
        state.devices = action.payload
      },
      setPatientData (state, action) {
        state.patientData = action.payload
      },
      setOrgName (state, action) {
        state.orgName = action.payload
      },
      setOrgTimeZone (state, action) {
        state.orgTimeZone = action.payload
      },
      setCurrentDateTime (state, action) {
        state.currentDateTime = action.payload
      }
    }
})

export const { setDevices, setOrgName, setPatientData, setOrgTimeZone, setCurrentDateTime } = LargeScreenSlice.actions

export default LargeScreenSlice.reducer