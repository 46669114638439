import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import "./staff.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllStaff, deleteStaffDetails } from "../../slices/thunk";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Table } from "reactstrap";
import Loader from "../../components/loader/Loader";
import StaffCreationForm from "./staffCreation";
import { baseURL } from "../../configuration/url";
import { Tooltip } from "@mui/material";
import axios from 'axios';
import { authorize } from "../../helpers/common";
import { Paginator } from "primereact/paginator";
import { setAllSetupOptions } from "../../slices/staff/reducer";
import { primarybg } from "../../common/primary";
import StaffUpdate from "./staffUpdate";
import LogoutConfirmationModal from "../../components/LogoutModel";

const Staff: React.FC = () => {
  const [selectStaffId, setSelectStaffId] = useState<string | null>(null);
  const dispatch = useDispatch<any>();
  const { staffData, loading, totalElements } = useSelector((state: any) => state.Staff);
  const { organization } = useSelector((state: any) => state.Login);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deletedStaff, setDeletedStaff] = useState<any>('')
  useEffect(() => {
    getAllStaff(dispatch, organization, searchTerm ? 0 : currentPage, searchTerm ? searchTerm : '');
  }, [dispatch, organization, searchTerm]);
  
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const [editModal, setEditModal] = useState(false);
  let [formData, setFormData] = useState({
    firstName: "",
    middleName: " ",
    lastName: " ",
    dateofBirth: " ",
    ssn: " ",
    npi: " ",
    addressLine1: " ",
    addressLine2: " ",
    city: " ",
    state: " ",
    zip: " ",
    mobilePhone: " ",
    email: " ",
    gender: " ",
    country: " ",
    roles: " ",
    speciality: " ",
    startDate: " ",
    active: " ",
    userType: "",
    profile: ""
  });

  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize()
      const apiDomain = `${baseURL}/dropdowns/getByDropdown?dropdown=`
      let errorArray: string[]
      try {
        const urls = [
          apiDomain + 'gender',
          apiDomain + 'speciality',
          apiDomain + 'roles'
        ]
        Promise.all(urls.map((url) => axios.get(url, { headers: header1 }))).then((data) => {
          const optionSelects = {
            Gender: data[0].data.data[0].list,
            Speciality: data[1].data.data[0].list,
            Roles: data[2].data.data[0].list
          }
          dispatch(setAllSetupOptions(optionSelects))
        }).catch((err: any) => {
          toast.error(errorArray[0])
        })
      } catch (error) {
        console.warn(error, 'checking the error')
      }
    }

    fetchDropdownData();
  }, []);
  const handleDeleteModalOpen = (staffName: any) => {
    setDeleteModal(!deleteModal)
    setDeletedStaff(staffName)
  }
  const handleDeleteModalclo = () => {
    setDeleteModal(!deleteModal)
    setDeletedStaff('')
  }
  const handleDelete = async (username: string) => {
    await dispatch(deleteStaffDetails(username, organization));
    handleDeleteModalclo()
  };
  const [selectedStaffName, setSelectedStaffName] = useState('');
  const handleClick = (selectStaff: any) => {
    if (selectStaff) {
      const fullName = `${selectStaff.name[0].given} ${selectStaff.name[0].family}`;
      setSelectedStaffName(fullName);
      const staffId = selectStaff.id || "";
      setSelectStaffId(staffId);
      const name = selectStaff.name?.[0] || {};
      const contact = selectStaff.contact?.[0]?.address?.[0] || {};
      setFormData({
        firstName: name.given || "",
        middleName: name.use || "",
        lastName: name.family || "",
        dateofBirth: selectStaff.dateofBirth || "",
        ssn: selectStaff.ssn || "",
        npi: selectStaff.npi || "",
        addressLine1: contact.addressLine1 || "",
        addressLine2: contact.addressLine2 || "",
        city: contact.city || "",
        state: contact.state || "",
        zip: contact.zip || "",
        mobilePhone: selectStaff.contact?.[0]?.mobilePhone || "",
        email: selectStaff.email || "",
        gender: selectStaff.gender || "",
        country: contact.country || "",
        roles: selectStaff.role || "",
        speciality: selectStaff.speciality?.[0] || "",
        startDate: selectStaff.startDate || "",
        active: selectStaff.active || " ",
        userType: selectStaff.userType || " ",
        profile: selectStaff.profile || ""
      });
      setEditModal(true);
    } else {
      console.error("Invalid staff data:", selectStaff);
    }
  };

  return (
    <div className="container m5 p3" style={{ width: '90%', paddingTop: "10px" }}>
      {loading && <Loader />}
      <div className="row">
        <div className="col-md-8 d-flex align-items-center">
          <h5>Staff List</h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center mb-1 gap-2">
        <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: '#1F489F', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
            <i style={{ fontSize: "36px", fontWeight: "lighter", color: "white" }} onClick={toggle} className="material-icons">add</i>
          </div>
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="search-icon mt-1" />
          </div>
          
        </div>
        <Table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead>
            <tr>
              <th scope="col" style={{width:'13px'}} className="text-center table-data">S.No</th>
              <th scope="col" className="text-center table-data">Staff Name</th>
              <th scope="col" className="text-center table-data">Role</th>
              <th scope="col" className="text-center table-data">Job Title</th>
              <th scope="col" className="text-center table-data">Action</th>
            </tr>
          </thead>
          <tbody>
            {staffData.length > 0 ? staffData?.map((staff: any, index: number) => (
              <tr key={index}>
                <td className="text-center">{currentPage * 10 + index + 1}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClick(staff)}
                >
                  {staff.name[0].given} {staff.name[0].family}
                </td>
                <td>{staff.userType}</td>
                <td>{staff.role}</td>
                <td className="text-center d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(staff)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-danger"
                      onClick={() => handleDeleteModalOpen(staff.username)}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </td>
              </tr>
            )) : 
            <tr>
              <td colSpan={7} className="text-center">
                <h5>No Staff found</h5>
              </td>
            </tr>
            }
          </tbody>
        </Table>
      </div>
      <StaffCreationForm modal={modal} toggle={toggle} currentPage={currentPage} />
      <div className="row">
        <div className="col-md-3">
        </div>
        <div className="pagination-container" >
          <div className="d-flex justify-content-center">
            <Paginator
              first={currentPage * 10}
              rows={10}
              totalRecords={totalElements}
              onPageChange={(e: any) => {
                setCurrentPage(e.page)
                getAllStaff(dispatch, organization, e.page, searchTerm ? searchTerm : '')
              }}
              currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
            />
          </div>
        </div>
      </div>
      <StaffUpdate 
        IsOpen={editModal} 
        CloseModal={setEditModal} 
        StaffName={selectedStaffName} 
        formData={formData} 
        setFormData={setFormData} 
        selectStaffId={selectStaffId} 
        currentPage={currentPage} 
      />
      <LogoutConfirmationModal open={deleteModal} handleConfirm={() => handleDelete(deletedStaff)} handleClose={() => handleDeleteModalclo() } message={"Are You Sure Do You Want To Delete?"}/>
    </div>
  );
};

export default Staff;