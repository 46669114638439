import React, { useEffect, useRef, useState } from 'react'
import Calendar from '../../components/calendar';
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { primarybg, primarytext } from '../../common/primary';
import { Button } from "primereact/button";
import { authorize, formatDate1 } from '../../helpers/common';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import { HttpLogin } from '../../utils/Http';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Autocomplete, List, ListItem, MenuItem, Select, TextField } from '@mui/material';
import { FaQrcode } from 'react-icons/fa';
import axios from 'axios';
import { getAllStaffLS } from '../../slices/thunk';
import LogoutConfirmationModal from '../../components/LogoutModel';
import moment from 'moment';

const StaffSos = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const videoRef = useRef(null);
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false);
    const [location, setLocation] = useState('');
    const [deviceId, setDeviceId] = useState('')
    const [dropdownData, setDropdownData] = useState([])
    const { userData } = useSelector((state: any) => state.Login);
    const staff = userData?.userDetail;
    const date1 = selectedDate.getDate().toString().padStart(2, '0');
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const forDate = `${year}${month}${date1}`
    const fetchSoSData = async () => {
        const dateStr = localStorage.getItem("getByDate")
        const staffId = localStorage.getItem("userID")
        const { header1 } = authorize();
        HttpLogin.axios().get(`${baseURL}/sosAlarm/sosAlarmRecordByDateAndOrgAndStaffId?date=${dateStr}&organization=${organization}&staffId=${staffId}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === 'MHC - 0200') {
                    setSlotRegister1(response.data.data.sosAlarmList);
                    setDeviceId(response.data.data.staffDevice)
                } else {
                    setSlotRegister1([]);
                    console.error('No Record:');
                }
            })
    }
    useEffect(() => {
        fetchSoSData();
    }, []);
    useEffect(() => {
        if (!slotRegister) return
        setDeviceId(slotRegister && slotRegister[slotRegister.length - 1].deviceId)
        return () => {
            setDeviceId('')
        }
    }, [])
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    const { organization } = useSelector((state: any) => state.Login)
    const [options, setOptions] = useState<any>([]);
    let [newRegister, setNewRegister] = useState<any>('');
    let [newIndex, setNewIndex] = useState<any>('');
    const [show, setShow] = useState(false);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(false);
    let addSlotRegister1 = [
        {
            staff: [
                {
                    deviceId: "",
                    registeredTime: "",
                    staffId: ""
                }
            ]
        }
    ]
    let [slotRegister, setSlotRegister1] = useState<any>(addSlotRegister1);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(slotRegister?.length / itemsPerPage);

    const handlePageChange = (pageNumber: any) => {
        setCurrentPage(pageNumber);
    };
    const startScanning = async () => {
        try {
            setScanning(true);
            const videoInputDevices = await codeReader.listVideoInputDevices();
            const selectedDeviceId = videoInputDevices[0].deviceId;
            const constraints = {
                video: { deviceId: selectedDeviceId },
            };
            codeReader.decodeFromVideoDevice(
                selectedDeviceId,
                videoRef.current,
                (result: any, err: any) => {
                    if (result) {
                        let newDeviceId = "";
                        const result1 = result.getText();
                        if (result1 !== "" && result1 !== undefined && result1 !== null) {
                            if (result1.length < 15) {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId;
                                setDeviceId(resultId)
                            } else {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId.slice(6, 23);
                                setDeviceId(newDeviceId)
                            }
                            setShow(false);
                            setScanning(false);
                        }
                        const scannedOption = options?.find((option: any) => option.deviceId === newDeviceId);

                        if (newRegister === "1") {
                            if (scannedOption) {
                                slotRegister[newIndex].deviceId = scannedOption.deviceId;
                                setSlotRegister1(slotRegister);
                            } else {
                                slotRegister[newIndex].deviceId = "";
                                setSlotRegister1(slotRegister);
                                toast.error("Scanned device ID not found in the options.");
                            }
                        }
                        const modal = document.getElementById("exampleModal");
                        if (modal) {
                            modal.classList.add("show");
                            modal.style.display = "block";
                        }

                    }
                    if (err && err.name === "NotFoundError") {
                        console.error("No QR code found in the video feed.");
                    }
                    if (err) {
                        console.error("Error during scanning:", err);
                    }

                },
            );
        } catch (error) {
            console.error("Error starting the scanner:", error);
        }
    };
    useEffect(() => {
        if (scanning) {
            startScanning();
        } else {
            codeReader.reset();
            setNewIndex('');
            setNewRegister([]);
        }

        return () => {
            codeReader.reset();
        };
    }, [scanning]);

    const handleConfirmModal = () => {
        setConfirmModal(!confirmModal)
    }
    const handleConfirmModalClo = () => {
        setConfirmModal(!confirmModal)
        setConfirmMessage('')
    }
    const handleSubmit = async (alertValue: boolean) => {
        const { header1 } = authorize();
        const bodyData = {
            staffId: staff.id,
            deviceId: deviceId,
            organization: organization,
            date: forDate,
            location: location,
            assignedBy: staff.id,
            singleAlert: alertValue,
            alert: alertValue,
            deActivateTime: ''
        }
        try {
            const response = await axios.post(`${baseURL}/sosAlarm/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                toast.success(response.data.message.description);
                closeModal()
                //   fetchSoSData();
                getAllStaffLS(dispatch, organization)
                setConfirmModal(false)
            } else if (response.data.message.code === 'MHC - 0128') {
                setConfirmMessage('The staff already has another beacon device. Do you want to remove and assign this device?')
                setConfirmModal(true)
                // const confirm = window.confirm('The staff already has another beacon device. Do you want to remove and assign this device?');
                //   if (confirm) {
                //       handleSubmit(true)
                //   }
                //   fetchSoSData();
            } else if (response.data.message.code === 'MHC - 0117') {
                setConfirmMessage('The beacon device was already assigned to another staff member. Do you want to confirm this device to assign it to you?')
                setConfirmModal(true)
                // const confirm = window.confirm('The beacon device was already assigned to another staff member. Do you want to confirm this device to assign it to you?')
                // if (confirm) {
                //     handleSubmit(true)
                // }
                // fetchSoSData();
            } else {
                toast.error("Request failed: " + response.data.message.description);
                closeModal()
            }
        } catch (error: any) {
            console.error("Error config:", error.config);
        }
    };

    const handleQrClick = () => {
        setShow(true);
        setScanning(!scanning);
    }
    useEffect(() => {
        const fetchData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/sensor/getStaffsBeacon?organization=${organization}`, { headers: header1 });
                setOptions(response.data.data);
            } catch (error) {
                setOptions([]);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchDropdownData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=Hospital Location`, { headers: header1 });
                if (response && response.data.message && response.data.message.code === successCode) {
                    setDropdownData(response.data.data[0].list);
                } else {
                    setDropdownData([]);
                    console.error(
                        "Error fetching dropdown data:",
                        response.data.message.description
                    );
                }
            } catch (error) {
                console.error("Error fetching dropdown data:", error);
            }
        };
        fetchDropdownData();
    }, []);

    const closeModalAndRec = () => {
        setShow(false)
        setScanning(false)
        codeReader.reset()
    }

    const formatDate = (date: any) => {
        const options = { day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const renderDateBoxes = () => {
        const dateBoxes = [];
        for (let i = -3; i <= 3; i++) {
            const currentDate = new Date(selectedDate);
            currentDate.setDate(selectedDate.getDate() + i);
            const date = selectedDate.getDate().toString().padStart(2, '0');
            const year = selectedDate.getFullYear();
            const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
            window.localStorage.setItem("getByDate", `${year}${month}${date}`)
            dateBoxes.push(
                <Calendar
                    key={i}
                    day={currentDate.toLocaleDateString('en-US', { weekday: 'short' })}
                    date={formatDate(currentDate)}
                    onClick={() => setSelectedDate(currentDate)}
                    isSelected={selectedDate?.toDateString() === currentDate.toDateString()}
                />
            );
        }
        return dateBoxes;
    };

    const handleClose = () => {
        setShow(false)
        setScanning(false)
        setNewIndex('');
        setNewRegister('');
        codeReader.reset()
    };

    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const closeModal = () => {
        setOpenModal(false)
        setLocation('')
        setDeviceId(slotRegister && slotRegister[slotRegister.length - 1].deviceId)
    }
    const handleDateChange = (event: any) => {
        const newDate = new Date(event.target.value);
        setSelectedDate(newDate);
    };
    const handleChange = (event: any, value: any) => {
        setLocation(value);
    };
    return (
        <div className='px-2' style={{ overflow: 'hidden' }}>
            <h5>Staff SOS Assignment</h5>
            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                {renderDateBoxes()}
                <div className="inpMain">
                    <input
                        type="date"
                        value={selectedDate.toISOString().split('T')[0]}
                        onChange={handleDateChange}
                    />
                    <div style={{ border: "1px groove" }} />
                    <img style={{ position: "absolute", left: '12px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
                </div>
            </div>
            <div className="row d-flex flex-row align-items-center py-2">
                <div className="col-md-5 d-flex justify-content-between" style={{ fontWeight: '500' }}>
                    <p style={{ color: primarybg, fontWeight: 'bold', margin: 0 }}>Staff Name: {staff && staff?.name[0]?.given + " " + staff?.name[0].family}</p>
                    Date: {forDate}
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-1">
                    <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                        <i style={{ fontSize: "34px", fontWeight: "lighter" }} onClick={handleOpenModal} className="material-icons">add</i>
                    </div>
                </div>
            </div>
            <Table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '13px', color: primarytext }} className="text-center table-data">S.No</th>
                        {/* <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Date</th> */}
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff Name</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Device ID</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Location</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Activate Time</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>De-Activate Time</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Assigned By</th>
                    </tr>
                </thead>
                <tbody >
                    {slotRegister?.length > 0 ? (
                        slotRegister?.slice()?.reverse().map((staff: any, index: any) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                {/* <td style={{ textAlign: "center" }}>{formatDate1(staff.date)}</td> */}
                                <td style={{ textAlign: "center" }}>{staff.staffName}</td>
                                <td style={{ textAlign: "center" }}>{staff.deviceId}</td>
                                <td style={{ textAlign: "center" }}>{staff.location}</td>
                                <td style={{ textAlign: "center" }}>{staff.activateTime && moment(`${staff.activateTime.slice(0, 4)}-${staff.activateTime.slice(4, 6)}-${staff.activateTime.slice(6, 8)} ${staff.activateTime.slice(8, 10)}:${staff.activateTime.slice(10, 12)}:${staff.activateTime.slice(12, 14)}`).format('MM-DD-YYYY HH:mm:ss')}</td>
                                <td style={{ textAlign: "center" }}>{staff.deActivateTime && moment(`${staff.deActivateTime.slice(0, 4)}-${staff.deActivateTime.slice(4, 6)}-${staff.deActivateTime.slice(6, 8)} ${staff.deActivateTime.slice(8, 10)}:${staff.deActivateTime.slice(10, 12)}:${staff.deActivateTime.slice(12, 14)}`).format('MM-DD-YYYY HH:mm:ss')}</td>
                                <td style={{ textAlign: "center" }}>{staff.assignedByName}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={8} className="text-center">There are no records...</td>
                        </tr>
                    )}

                </tbody>
            </Table>
            <Pagination>
                <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink
                        onClick={() => handlePageChange(currentPage - 1)}
                        previous
                    />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                    <PaginationItem active={i + 1 === currentPage} key={i}>
                        <PaginationLink onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink
                        onClick={() => handlePageChange(currentPage + 1)}
                        next
                    />
                </PaginationItem>
            </Pagination>

            <Modal show={openModal} className={`${confirmModal && 'z-2'}`} style={{ transition: `${confirmModal && 'width 2s'}` }} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{staff && staff?.name[0]?.given + " " + staff?.name[0].family}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <p>Device ID</p>
                    </div>
                    <div className="row">
                        <div className='col-md-6' style={{ position: 'relative', paddingLeft: 0, padding: 0 }}>
                            <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                                <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                                    <Select
                                        // multiple
                                        value={deviceId}
                                        onChange={(e) => setDeviceId(e.target.value)}
                                        style={{ minHeight: '50px', width: '300px', marginLeft: '12px' }}
                                        IconComponent={() => (
                                            <FaQrcode
                                                className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                                                role='button'
                                                style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                                            />
                                        )}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '300px',
                                                },
                                            },
                                        }}
                                    >
                                        {options?.length > 0 ? options?.map((option: any) => (
                                            <MenuItem key={option.id} value={option.deviceId}>
                                                {option.deviceId}
                                            </MenuItem>
                                        )) :
                                            <MenuItem>
                                                No Device Data
                                            </MenuItem>
                                        }
                                    </Select>
                                </ListItem>
                            </List>
                        </div>
                        <div className="col-md-6">
                            <Autocomplete
                                id={'organizationDetails'}
                                options={dropdownData?.map((item: any) => item.value)}
                                value={location}
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params} label={'Location'} variant="outlined" />}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ gap: '4px' }}>
                    <Button label={'Close'} variant="secondary" style={{ backgroundColor: '#94a0b7', fontSize: '12px' }} onClick={closeModal} >
                    </Button>
                    <Button label={'Save Changes'} style={{ backgroundColor: '#0f3995', fontSize: '12px' }} onClick={() => handleSubmit(false)} />
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Scanning</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button label={'Close'} variant="secondary" onClick={handleClose} >
                    </Button>
                    <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995' }} onClick={closeModalAndRec} />
                </Modal.Footer>
            </Modal>
            <LogoutConfirmationModal open={confirmModal} handleConfirm={() => handleSubmit(true)} handleClose={() => handleConfirmModalClo()} message={confirmMessage} />
        </div>
    )
}

export default StaffSos;